import React from 'react';
import { ReactComponent as LockSvg } from 'assets/lock2.svg';
import { ReactComponent as AddAudio } from 'assets/ticket/add_audio.svg';
import { ReactComponent as AddFile } from 'assets/ticket/add_file.svg';
import { ReactComponent as AddImage } from 'assets/ticket/add_image.svg';
import { ReactComponent as TicketSend } from 'assets/ticket/ticket_send.svg';

const icons = {
  lock: LockSvg
};

const ticketIcons = {
  'add-audio': AddAudio,
  'add-file': AddFile,
  'add-image': AddImage,
  send: TicketSend
};

type IconProps = {
  name: keyof typeof icons;
  style?: React.CSSProperties;
  className?: string;
};

type TicketIconProps = {
  name: keyof typeof ticketIcons;
  style?: React.CSSProperties;
  className?: string;
};

const CustomIcon: React.FC<IconProps> = ({ name, style, className }) => {
  const IconComponent = icons[name];
  return <IconComponent style={style} className={className} />;
};

export const CustomTicketIcon: React.FC<TicketIconProps> = ({ name, style, className }) => {
  const IconComponent = ticketIcons[name];
  return <IconComponent style={style} className={className} />;
};

export default CustomIcon;
