import { Typography } from '@mui/material';
import { myTheme } from 'theme/theme';

const CustomPageHeader = ({ header, sx }: { header: string; sx?: any }) => (
  <Typography
    variant='h4'
    sx={{ marginBottom: '24px', color: myTheme.palette.fontColor, fontWeight: '600', ...sx }}>
    {header}
  </Typography>
);

export default CustomPageHeader;
