import CustomButton from 'components/Button/CustomButton';
import { useTranslation } from 'react-i18next';
import { InnerWrapper, Wrapper } from './styled';
import useBluetooth from 'configurator/hooks/bluetooth/useConnect';
import { ReactComponent as HandDeviceSvg } from 'assets/hand-device.svg';
import { ReactComponent as DisconnectBluetoothSvg } from 'assets/bt.svg';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useQueryClient } from '@tanstack/react-query';
import { checkDeviceSerial } from 'configurator/api/device/device';
import { CHECK_DEVICE_SERIAL_QUERY_KEY } from 'configurator/hooks/api/useDevice';
import { getUserMe } from 'adp-panel/api/users/users';
import { UserExtendOptions, UsersQueryParams } from 'adp-panel/api/users/users.types';
import { USER_QUERY_KEY } from 'adp-panel/hooks/api/useUsers';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useSessionStart } from 'hooks/useSessionStart';
import CustomChip from 'components/CustomChip/CustomChip';
import { NotificationFactory } from 'lib/NotificationFactory';
import { Link } from '@mui/material';
import { TEXTS } from 'constants/texts';
import { useNavigate } from 'react-router-dom';
import { CHOOSE_GRIPS } from 'constants/routes';
import { useDeviceAdd } from 'hooks/useDevices';

const Navbar = () => {
  const { t } = useTranslation();
  const { bluetoothDisconnect, bluetoothGetBasicInformation } = useBluetooth();
  const { connected, setItemDeviceInfo, serial } = useDeviceInfoStore((state) => ({
    connected: state.connected,
    setItemDeviceInfo: state.setItemDeviceInfo,
    serial: state.serial
  }));
  const { getInitialConfigAPI } = useConfigStore((state: any) => ({
    getInitialConfigAPI: state.getInitialConfigAPI
  }));
  const queryClient = useQueryClient();
  const { handleOpenLocalSession } = useSessionStart();
  const navigate = useNavigate();
  const { mutateAsync: addDevice } = useDeviceAdd();

  const queryParams: UsersQueryParams = {
    extend: [UserExtendOptions.devices, UserExtendOptions.devicesAsClinician]
  };

  const checkIfHandExists = async (serialNumber: string) => {
    const serialStatus = await queryClient.fetchQuery([CHECK_DEVICE_SERIAL_QUERY_KEY], () =>
      checkDeviceSerial(serialNumber)
    );

    return Boolean(serialStatus?.status);
  };

  const checkIfHandIsAssignedToMe = async (serialNumber: string) => {
    const userData = await queryClient.fetchQuery([USER_QUERY_KEY], () =>
      getUserMe({ params: queryParams })
    );

    if (userData?.devices_as_clinician && userData?.devices_as_clinician?.length > 0) {
      const device = userData?.devices_as_clinician.find(
        (_device) => _device.serial === serialNumber
      );
      if (device) return device;
    }

    return false;
  };

  const connectDeviceConfigurator = async (
    serial: string,
    bluetooth_id: string,
    deviceId: number
  ) => {
    setItemDeviceInfo('deviceId', deviceId);
    await getInitialConfigAPI();
    handleOpenLocalSession(serial, bluetooth_id, deviceId, true);
  };

  const handleBluetooth = async () => {
    if (connected) {
      bluetoothDisconnect();
      return;
    }

    const deviceData = await bluetoothGetBasicInformation();

    if (!deviceData || !deviceData?.serialNumber) {
      NotificationFactory.errorNotification(
        t('common:component.navbar.device_not_existing', {
          email: <Link href={`mailto:${TEXTS.SUPPORT_EMAIL}`}> {TEXTS.SUPPORT_EMAIL}</Link>,
          defaultValue:
            'The device you are trying to connect with does not exist in ADP, please contact us at {{email}} to resolve the issue.'
        })
      );
      return;
    }

    const assignedDevice = await checkIfHandIsAssignedToMe(deviceData?.serialNumber);

    if (assignedDevice) {
      connectDeviceConfigurator(
        assignedDevice.serial!,
        assignedDevice.bluetooth_id,
        assignedDevice.id
      );
      return;
    }

    const isHandInAdp = await checkIfHandExists(deviceData?.serialNumber);

    if (!isHandInAdp) {
      NotificationFactory.errorNotification(
        t('common:component.navbar.code_used', {
          email: <Link href={`mailto:${TEXTS.SUPPORT_EMAIL}`}> {TEXTS.SUPPORT_EMAIL}</Link>,
          defaultValue:
            'The code you provided is no longer active, please contact us at {{email}} to resolve the issue.'
        })
      );
      return;
    }

    // Assign device
    await addDevice({ serial: deviceData?.serialNumber });

    // Connect to configurator
    const newAssignedDevice = await checkIfHandIsAssignedToMe(deviceData?.serialNumber);

    if (newAssignedDevice) {
      connectDeviceConfigurator(
        newAssignedDevice.serial!,
        newAssignedDevice.bluetooth_id,
        newAssignedDevice.id
      );
      return;
    }
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <div>Hello</div>
        <div style={{ display: 'flex', gap: '12px' }}>
          {connected && (
            <CustomButton color='light' onClick={() => navigate(CHOOSE_GRIPS)}>
              <CustomChip
                color='success'
                label={t('common:component.navbar.connected_sn', {
                  defaultValue: 'Connected (SN: {{serial}})',
                  serial
                })}
                sx={{ borderRadius: '6px', padding: '0', marginRight: '12px', height: '0' }}
              />
              {t('common:component.navbar.back_to_configurator', 'Back to configurator')}
            </CustomButton>
          )}
          <CustomButton
            color={connected ? 'light' : 'primary'}
            Icon={connected ? DisconnectBluetoothSvg : HandDeviceSvg}
            onClick={handleBluetooth}>
            {connected
              ? t('common:component.navbar.disconnect_sn', {
                  defaultValue: 'Disconnect (SN: {{serial}})',
                  serial
                })
              : t('common:component.navbar.connect_device', 'Connect device')}
          </CustomButton>
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Navbar;
