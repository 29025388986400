import * as Sentry from '@sentry/react';
import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import { getFileName, getValidFiles } from 'adp-panel/utils/getFileName';
import { MEDICAL_PROFESSIONALS } from 'constants/routes';
import { NotificationFactory } from 'lib/NotificationFactory';
import { isNil, omitBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import useUserData from '../../../hooks/useUserData';
import { PermissionEntry, RoleEnum, UserUpdatePayload } from '../../api/users/users.types';
import permanentPermissions from '../../constants/permanentPermissions';
import { comparePermissions, convertToPermissionString, userHasRole } from '../../hoc/useCanAccess';
import { useUserUpdate } from '../../hooks/api/useUsers';
import { countryCodeMap, countryCodeReversedMap } from '../../utils/definesLocal';
import { parseCountryCodeFromAPI, parsePhoneFromAPI } from '../../utils/parsePhone';
import { userHasPermissions } from '../../utils/permissionUtils';
import ClinicianForm from './ClinicianForm';

const EditClinician = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('clinicians');
  const { rolesByName } = useUserData();
  const { state } = useLocation();
  const { item: user = undefined }: any = state;
  const { mutateAsync: editUser, isLoading: isLoadingUpdated } = useUserUpdate();

  const handleSubmit = async ({
    name = undefined,
    email,
    image = undefined,
    phone = undefined,
    phone_code = undefined,
    location,
    permissions = [],
    patients = [],
    role
  }: any) => {
    try {
      const permissionFromForm = convertToPermissionString(patients, [
        ...permissions,
        ...permanentPermissions
      ]);
      const permissionFromApi = user.permissions.map((item: PermissionEntry) => item.name);
      const toAdd = comparePermissions(permissionFromForm, permissionFromApi);
      const toRemove = comparePermissions(permissionFromApi, permissionFromForm);

      const permissionsKey = {
        ...(toAdd.length > 0 && { add: toAdd }),
        ...(toRemove.length > 0 && { del: toRemove })
      };

      await editUser(
        omitBy(
          {
            id: user.id,
            name,
            email: user.email === email ? undefined : email,
            phone: `${countryCodeReversedMap.get(phone_code)}-${phone}`,
            location: location?.id,
            image: getValidFiles(image),
            ...(image?.length === 0 && { image_delete: 1 }),
            permissions: permissionsKey,
            role: role.id
          },
          isNil
        ) as UserUpdatePayload
      );
      NotificationFactory.successNotification(
        t('clinicians:component.edit_clinician.notification.success', 'User updated')
      );
      navigate(MEDICAL_PROFESSIONALS);
    } catch (error) {
      Sentry.captureException(error);
      NotificationFactory.errorNotification(
        t('clinicians:component.edit_clinician.notification.error', 'Failed updated clinician')
      );
    }
  };
  const getFormTitle = () => {
    if (userHasRole(user, RoleEnum.clinicianSupport)) {
      return t(
        'clinicians:component.edit_clinician.form_title_allied_health',
        'Edit Allied Health'
      );
    }

    if (userHasRole(user, RoleEnum.clinicAdmin)) {
      return t('clinicians:component.edit_clinician.form_title_clinic_admin', 'Edit clinic admin');
    }

    return t('clinicians:component.edit_clinician.form_title_clinician', 'Edit clinician');
  };

  const transformInitialData = (userData: any) => {
    const phoneCode = parseCountryCodeFromAPI(userData?.phone);
    return {
      ...userData,
      ...(userData?.image && { image: [{ name: getFileName(userData?.image) }] }),
      phone_code: phoneCode ? countryCodeMap.get(phoneCode) : '',
      phone: parsePhoneFromAPI(userData?.phone)
    };
  };

  return (
    <DefaultLayout>
      <FormContentWrapper title={getFormTitle()} small>
        <ClinicianForm
          onSubmit={handleSubmit}
          isAdmin={userHasPermissions([RoleEnum.superAdmin], rolesByName)}
          user={transformInitialData(user)}
          isUpdate={true}
          isLoading={isLoadingUpdated}
        />
      </FormContentWrapper>
    </DefaultLayout>
  );
};
export default EditClinician;
