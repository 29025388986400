import { Chip, Typography } from '@mui/material';
import { GripSwitchingModes } from 'bluetooth/Bluetooth/Control';
import { useConfigStore } from 'configurator/reducers/configStore';
import { getCurrentConfigSelector } from 'configurator/reducers/helpers/selectors';
import { gripSwitchingMap } from 'configurator/utils/definesLocal';
import { useTranslation } from 'react-i18next';

export const GripSwitchingModeIndicator = ({
  primary = true,
  style
}: {
  primary?: boolean;
  style?: any;
}) => {
  const { gripSwitchingMode } = useConfigStore(getCurrentConfigSelector);
  const { t } = useTranslation();
  const primaryGripSwitchingMap = {
    [GripSwitchingModes.kCoContraction]: t(
      'configurator:grip_switching.short_co_contraction',
      'Short co-contraction'
    ),
    [GripSwitchingModes.kOpenOpen]: t(
      'configurator:grip_switching.two_open_pulses',
      '2 open pulses'
    ),
    [GripSwitchingModes.kHoldOpen]: t(
      'configurator:grip_switching.short_hold_open',
      'Short hold-open'
    )
  };

  const secondaryGripSwitchingMap = {
    [GripSwitchingModes.kCoContraction]: t(
      'configurator:grip_switching.long_co_contraction',
      'Long co-contraction'
    ),
    [GripSwitchingModes.kOpenOpen]: t(
      'configurator:grip_switching.three_open_pulses',
      '3 open pulses'
    ),
    [GripSwitchingModes.kHoldOpen]: t(
      'configurator:grip_switching.long_hold_open',
      'Long hold-open'
    )
  };

  return (
    <div style={style}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography>{t(gripSwitchingMap.get(gripSwitchingMode?.[0]))}</Typography>
        <Chip
          size='small'
          label={
            primary
              ? primaryGripSwitchingMap[gripSwitchingMode?.[0]]
              : secondaryGripSwitchingMap[gripSwitchingMode?.[0]]
          }
          sx={{ backgroundColor: '#D8E3FF' }}
        />
      </div>
    </div>
  );
};
