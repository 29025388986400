/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import ProgressBar from '@ramonak/react-progress-bar';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import { Button, DialogContent } from '@mui/material';
import platform from 'platform';
import useCompatibilities from 'configurator/hooks/useCompatibilities';
import Divider from 'configurator/components/atoms/Divider/Divider';
import BootloaderController from 'configurator/bluetooth-handler/bootloaderController';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useDeviceUpdate } from 'configurator/hooks/api/useDevice';
import { useSettingsStore } from 'configurator/reducers/settingsStore';
import { ReactComponent as Check } from 'assets/check-solid.svg';
import { ReactComponent as Times } from 'assets/times-solid.svg';
import { HeaderM, TextS } from 'configurator/components/atoms/Typography/Typography';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import { FIRMWARE_COMPLETE_NOTIFICATION } from 'configurator/consts/notifications';
import { CHOOSE_GRIPS } from 'constants/routes';
import {
  ALLOW_MANUAL_UPDATE,
  CURRENT_BOOTLOADER_VERSION,
  FETCHING_STATES
} from 'configurator/consts/consts';
import { Blinker } from 'configurator/utils/Blinker';
import useBluetooth from 'configurator/hooks/bluetooth/useConnect';
import { useUiStore } from 'configurator/reducers/uiStore';
import ModalBase from './ModalBase';

const Header1 = styled.h3`
  ${HeaderM};
`;

const Paragraph = styled.p`
  ${TextS}
`;

const Regular = styled.span`
  font-weight: 400;
`;

const StyledIcon = css`
  width: 20px;
  margin-right: 5px;

  @media (max-height: 924px) {
    width: 15px;
  }
`;

export const StyledCheck = styled(Check)`
  color: #16c60c;
  ${StyledIcon};
`;

export const StyledTimes = styled(Times)`
  color: #ee0000;
  ${StyledIcon};
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const loaderAnimation = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

const Loader = styled.div`
  width: 20px;
  height: 20px;
  z-index: 999;
  margin-right: 5px;

  &::after {
    content: ' ';
    display: block;
    width: 20px;
    height: 20px;
    margin: 2px;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colorPrimary};
    border-color: ${({ theme }) => theme.colorPrimary} transparent
      ${({ theme }) => theme.colorPrimary} transparent;
    animation: ${loaderAnimation} 1.2s linear infinite;
  }
`;

const Bootloader = new BootloaderController();
const BlinkerTab = new Blinker('#elevatorMusicId');

const UpdateStates = {
  notInititated: 0,
  fetchSuccesful: 1,
  failedToFetch: 2,
  inProgress: 3,
  succesful: 4,
  failed: 5,
  inProgressFix: 6,
  fixSuccessful: 7,
  fixFailed: 8
};

const FirmwareModal = ({ handleClose }: any) => {
  const { t } = useTranslation('configurator');
  const [message, setMessage] = useState<string | null>(null);
  const [currentPart, setCurrentPart] = useState(0);
  const { updateStatus, maxParts, setItemSettingsStore } = useSettingsStore((state: any) => ({
    updateStatus: state.updateStatus,
    maxParts: state.maxParts,
    setItemSettingsStore: state.setItemSettingsStore
  }));
  const { bluetoothConnect } = useBluetooth();
  const { connectionState, firmwareUpdateState, setItemUiStore } = useUiStore((state: any) => ({
    connectionState: state.connectionState,
    firmwareUpdateState: state.firmwareUpdateState,
    setItemUiStore: state.setItemUiStore
  }));
  const { deviceId, deviceConnected, supported, versions, pcbVersion } = useDeviceInfoStore(
    (state: any) => ({
      deviceId: state.deviceId,
      deviceConnected: state.connected,
      supported: state.supported,
      versions: state.versions,
      pcbVersion: state.pcbVersion
    })
  );
  const {
    availableFirmwares,
    isFirmwareUpdateAvailable,
    isFirmwareUpdateNeeded,
    deviceFirmwareUnknown
  } = useCompatibilities();
  const { mutateAsync: updateDevice } = useDeviceUpdate();
  const navigate = useNavigate();
  const { disconnectDevice, getInitialConfigAPI } = useConfigStore((state: any) => ({
    disconnectDevice: state.disconnectDevice,
    getInitialConfigAPI: state.getInitialConfigAPI
  }));
  const recentFirmware = `${availableFirmwares?.[0]?.[`file_firmware_v${pcbVersion}`]}`;
  const recentBootloader = `${availableFirmwares?.[0]?.[`file_bootloader_v${pcbVersion}`]}`;
  const isMacOs = platform?.os?.family === 'OS X';

  const updateFirmwareWeb = async (firmwareURL: any, duringFix = false) => {
    try {
      const request = await fetch(firmwareURL);
      if (request.ok) {
        const bytes = await request.arrayBuffer();
        Bootloader.updateBootloaderParts(bytes);
        const max = Bootloader.getMaxParts();
        setCurrentPart(0);
        setItemSettingsStore('maxParts', max);
        return true;
      }
      throw new Error('Bad response status');
    } catch (err) {
      setItemSettingsStore('updateStatus', UpdateStates.failedToFetch);
      return false;
    }
  };

  const update = async () => {
    setItemSettingsStore('updateStatus', UpdateStates.inProgress);
    BlinkerTab.start();
    const status = await Bootloader.updateFirmwareNew();
    setItemSettingsStore(
      'updateStatus',
      status === 2 ? UpdateStates.succesful : UpdateStates.failed
    );
    BlinkerTab.stop();

    await disconnectDevice();

    // If update is successful, set isAfterUpdate and copy current settings
    if (status === 2) {
      await updateDevice({
        deviceId: Number(deviceId),
        data: { firmware_version_id: availableFirmwares[0].id }
      });
      await getInitialConfigAPI();
      toast(
        t(
          FIRMWARE_COMPLETE_NOTIFICATION.translationKey,
          FIRMWARE_COMPLETE_NOTIFICATION.translationOptions
        ),
        FIRMWARE_COMPLETE_NOTIFICATION.options
      );
    }

    if (isMacOs) return;

    // Auto-reconnect
    await delay(5000);
    bluetoothConnect();
  };

  const updateWithFix = async (firmwareFileUrl: any, bootloaderFileUrl: any) => {
    setItemUiStore('firmwareUpdateState', FETCHING_STATES.loading);
    BlinkerTab.start();
    try {
      await getInitialConfigAPI();
      const fetchStatus = await updateFirmwareWeb(firmwareFileUrl);
      if (fetchStatus) {
        let statusRecent;
        setItemSettingsStore('updateStatus', UpdateStates.inProgress);
        const bootloaderVersion = await Bootloader.checkBootloaderVersion();
        if (bootloaderVersion < CURRENT_BOOTLOADER_VERSION) {
          setItemSettingsStore('updateStatus', UpdateStates.inProgressFix);
          await updateFirmwareWeb(bootloaderFileUrl, true);
          const statusFix = await Bootloader.updateFirmware();
          setItemSettingsStore(
            'updateStatus',
            statusFix === 2 ? UpdateStates.fixSuccessful : UpdateStates.fixFailed
          );
        }
        if (updateStatus !== UpdateStates.fixFailed) {
          await delay(2000);
          await updateFirmwareWeb(firmwareFileUrl);
          statusRecent = await Bootloader.updateFirmwareNew();
          setItemSettingsStore(
            'updateStatus',
            // @ts-ignore
            statusRecent === 2 || statusRecent === false
              ? UpdateStates.succesful
              : UpdateStates.failed
          );
          BlinkerTab.stop();
        }

        await disconnectDevice();

        // If update is successful, set isAfterUpdate and copy current settings
        // @ts-ignore
        if (statusRecent === 2 || statusRecent === false) {
          await updateDevice({
            deviceId: Number(deviceId),
            data: { firmware_version_id: availableFirmwares[0].id }
          });
          await getInitialConfigAPI();
          toast(
            t(
              FIRMWARE_COMPLETE_NOTIFICATION.translationKey,
              FIRMWARE_COMPLETE_NOTIFICATION.translationOptions
            ),
            FIRMWARE_COMPLETE_NOTIFICATION.options
          );
        }
        setItemUiStore('firmwareUpdateState', FETCHING_STATES.successful);

        if (isMacOs) return;

        // Auto-reconnect
        await delay(5000);
        bluetoothConnect();
        return;
      }
      setItemUiStore('firmwareUpdateState', FETCHING_STATES.failed);
      BlinkerTab.stop();
    } catch (e) {
      console.log(e);
      setItemUiStore('firmwareUpdateState', FETCHING_STATES.failed);
      BlinkerTab.stop();
    }
  };

  const loadFile = async () => {
    const firmware: HTMLInputElement | null = document.querySelector('#firmwareInput');

    if (!firmware) return;

    const files = firmware.files![0];
    await updateFirmwareWeb(window.URL.createObjectURL(files));
    update();
  };

  useEffect(() => {
    switch (updateStatus) {
      case UpdateStates.notInititated:
        setMessage(null);
        break;
      case UpdateStates.fetchSuccesful:
        setMessage(
          t(
            'configurator:component.firmware_modal.files_ready',
            'Files ready, click update to proceed'
          )
        );
        break;
      case UpdateStates.failedToFetch:
        setMessage(
          t('configurator:component.firmware_modal.failed_to_download', 'Failed to download files')
        );
        break;
      case UpdateStates.inProgress:
        setMessage(
          t('configurator:component.firmware_modal.update_in_progress', 'Update in progress')
        );
        break;
      case UpdateStates.succesful:
        setMessage(
          t(
            'configurator:component.firmware_modal.update_successful',
            'Update successful, please {{reconnectionMessage}}',
            {
              reconnectionMessage: isMacOs ? 'reconnect' : 'wait for reconnection'
            }
          )
        );
        break;
      case UpdateStates.failed:
        setMessage(
          t(
            'configurator:component.firmware_modal.update_failed',
            'Update failed, please wait for reconnection'
          )
        );
        break;
      case UpdateStates.inProgressFix:
        setMessage(
          t(
            'configurator:component.firmware_modal.bootloader_update_in_progress',
            'Bootloader update in progress'
          )
        );
        break;
      case UpdateStates.fixSuccessful:
        setMessage(
          t(
            'configurator:component.firmware_modal.bootloader_update_successful',
            'Bootloader updated successfully, wait for the firmware update to finish'
          )
        );
        break;
      case UpdateStates.fixFailed:
        setMessage(
          t(
            'configurator:component.firmware_modal.bootloader_update_failed',
            'Bootloader update failed'
          )
        );
        break;
      default:
        break;
    }
  }, [updateStatus]);

  const updateCurrentPart = (data: any) => {
    setCurrentPart(data.detail);
  };

  function handleLeave(event: any) {
    event.returnValue = t(
      'configurator:component.firmware_modal.sure_you_want_to_leave',
      'Sure you want to leave?'
    );
  }

  useEffect(() => {
    navigate(CHOOSE_GRIPS);

    window.addEventListener(`bootloaderProgressUpdate`, updateCurrentPart);
    window.addEventListener('beforeunload', handleLeave);

    return function clean() {
      window.removeEventListener(`bootloaderProgressUpdate`, updateCurrentPart);
      window.removeEventListener(`beforeunload`, handleLeave);
    };
  }, []);

  const isInProgress =
    updateStatus === UpdateStates.inProgress ||
    updateStatus === UpdateStates.succesful ||
    updateStatus === UpdateStates.failed ||
    updateStatus === UpdateStates.inProgressFix ||
    updateStatus === UpdateStates.fixSuccessful;

  const isInProgressStrict =
    updateStatus === UpdateStates.inProgress ||
    updateStatus === UpdateStates.inProgressFix ||
    updateStatus === UpdateStates.fixSuccessful;

  const updateStatesSucessfull =
    updateStatus === UpdateStates.fetchSuccesful || updateStatus === UpdateStates.succesful;

  const updateStatesFailed =
    updateStatus === UpdateStates.failedToFetch || updateStatus === UpdateStates.failed;

  const firmwareVersionCurrentParsed =
    versions?.current?.[1] !== undefined &&
    versions?.current?.[4] !== undefined &&
    versions?.current?.[7] !== undefined
      ? `${versions?.current?.[1]}.${versions?.current?.[4]}.${versions?.current?.[7]}`
      : '';
  const inBootloaderMode = deviceConnected && !versions?.current?.[1];

  const allowUpdate =
    isFirmwareUpdateAvailable ||
    isFirmwareUpdateNeeded ||
    inBootloaderMode ||
    deviceFirmwareUnknown;

  return (
    <ModalBase
      header={t('configurator:component.firmware_modal.update_firmware', 'Update firmware')}
      hideCloseButton={isInProgress || !supported || inBootloaderMode}
      handleClick={isInProgressStrict || !supported ? () => true : handleClose}
      allowBackDropClose={false}>
      <audio
        id='elevatorMusicId'
        src='https://aetherbiomedical-images.s3.amazonaws.com/elevator.mp3'
        preload='auto'
        loop
      />
      <DialogContent sx={{ width: '450px' }}>
        {allowUpdate && (
          <>
            <p>
              {t(
                'configurator:component.firmware_modal.update_firmware_message',
                'Some browsers may limit the Bluetooth connection in the event of inactivity. Relaxing music will be played for you to maintain the connection while waiting for the update.'
              )}
            </p>
            <Divider margin='14px' />
          </>
        )}
        {!inBootloaderMode && (
          <Header1>
            {t('configurator:component.firmware_modal.device_version', 'Device version: ')}
            <Regular>{firmwareVersionCurrentParsed}</Regular>
          </Header1>
        )}
        <>
          <Header1>
            {allowUpdate ? (
              <>
                t{t('configurator:component.firmware_modal.newest_version', 'Newest version: ')}
                <Regular>{availableFirmwares?.[0]?.name}</Regular>
              </>
            ) : (
              <div style={{ color: 'green' }}>
                {t(
                  'configurator:component.firmware_modal.firmware_up_to_date',
                  'Your firmware is up to date'
                )}
              </div>
            )}
          </Header1>
          <Divider margin='20px' />
        </>
        {message && (
          <>
            <InfoWrapper>
              {updateStatesSucessfull && <StyledCheck />}
              {updateStatus === UpdateStates.inProgress && <Loader />}
              {updateStatesFailed && <StyledTimes />}
              <Paragraph>{message}</Paragraph>
            </InfoWrapper>
          </>
        )}
        {(updateStatus === UpdateStates.notInititated ||
          updateStatus === UpdateStates.failedToFetch) && (
          <>
            {allowUpdate &&
              (pcbVersion ? (
                <>
                  <Divider margin='10px' />
                  <LoadingButton
                    onClick={() => updateWithFix(recentFirmware, recentBootloader)}
                    fullWidth
                    loading={firmwareUpdateState === FETCHING_STATES.loading}>
                    <span>Update</span>
                  </LoadingButton>
                </>
              ) : (
                <span style={{ color: 'red' }}>
                  {t(
                    'configurator:component.firmware_modal.pcb_version_not_found',
                    'PCB version can&rsquo;t be determined. Firmware update can&rsquo;t be performed. Please contact Aether support.'
                  )}
                </span>
              ))}
            {(ALLOW_MANUAL_UPDATE || inBootloaderMode) && (
              <>
                <Divider margin='10px' />
                <input
                  type='file'
                  id='firmwareInput'
                  accept='.bin'
                  onChange={loadFile}
                  style={{ display: 'none' }}
                />
                <Button
                  onClick={() => {
                    const selectFirmwareButton: HTMLElement | null =
                      document.querySelector('#firmwareInput');

                    if (!selectFirmwareButton) return;

                    selectFirmwareButton.click();
                  }}
                  fullWidth>
                  {t('configurator:component.firmware_modal.update_from_file', 'Update from file')}
                </Button>
              </>
            )}
          </>
        )}
        {!supported && (
          <>
            <Divider margin='10px' />
            <span style={{ color: 'red' }}>
              {t(
                'configurator:component.firmware_modal.firmware_not_supported',
                'Version 1.8.0 is needed to use ADP, please update firmware.'
              )}
            </span>
          </>
        )}
        {isInProgress && (
          <>
            <Divider margin='10px' />
            <ProgressBar
              completed={maxParts ? Math.ceil((currentPart / maxParts) * 100) : 0}
              bgColor='#3788B3'
              transitionDuration='0.3s'
            />
            <Divider margin='10px' />
            {isInProgressStrict && (
              <p style={{ color: 'red' }}>
                {t(
                  'configurator:component.firmware_modal.do_not_turn_off',
                  'Do not turn off the device, close the application or change active browser tab until firmware update will be done.'
                )}
              </p>
            )}
          </>
        )}
        {!deviceConnected && isMacOs && (
          <>
            <Divider margin='10px' />
            <LoadingButton
              loading={connectionState === FETCHING_STATES.loading}
              fullWidth
              onClick={() => bluetoothConnect()}>
              <span>{t('configurator:component.firmware_modal.reconnect', 'Reconnect')}</span>
            </LoadingButton>
          </>
        )}
      </DialogContent>
    </ModalBase>
  );
};

FirmwareModal.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default FirmwareModal;
