import api from '../utils/apiClient';
import { encodeQueryData } from '../utils/encodeQuery';
import {
  DeviceConfigEntry,
  DeviceEntry,
  DeviceQueryParams,
  DevicesQueryParams
} from './device.types';
import { PaginatedResponse } from '../../../types';
import { AxiosResponse } from 'axios';

export const GET_DEVICE = 'api/device/{id}';
export const GET_DEVICES = 'api/devices';
export const GET_USER_DEVICES = 'api/user/{id}/devices';
export const GET_DEVICES_MODELS = 'api/devices/models';
export const GET_DEVICE_CONFIG = 'api/device/{id}/config';
export const GET_DEVICE_CONFIG_HISTORY = 'api/device/{id}/config/history';
export const CREATE_DEVICE = 'api/device';
export const DELETE_DEVICE = 'api/device/{id}';
export const UPDATE_DEVICE = 'api/device/{id}';
export const ADD_DEVICE = 'api/device/add/{serial}';

const withCredentials = {
  withCredentials: true
};

export const getDevice = (deviceId: number, params: DeviceQueryParams = {}): Promise<any> => {
  const queryString = encodeQueryData({
    ...params,
    extend: params.extend?.toString()
  });
  return api
    .get(`${GET_DEVICE.replace('{id}', `${deviceId}`)}?${queryString}`, withCredentials)
    .then((res: any) => res.data);
};

export const getDevices = (params: DevicesQueryParams = {}): Promise<any> => {
  const queryString = encodeQueryData({
    ...params,
    extend: params.extend?.toString()
  });
  return api
    .get<PaginatedResponse<DeviceEntry>>(`${GET_DEVICES}?${queryString}`, withCredentials)
    .then((res: AxiosResponse) => res.data);
};

export const getUserDevices = (userId: number, params: any = {}): Promise<any> => {
  const queryString = encodeQueryData({ ...params, extend: params.extend?.toString() });
  return api
    .get(`${GET_USER_DEVICES.replace('{id}', `${userId}`)}?${queryString}`, withCredentials)
    .then((res: any) => res.data);
};

export const getDeviceConfigHistory = (deviceId: number, queryParams: any = {}) => {
  const queryString = encodeQueryData(queryParams);
  return api
    .get(
      `${GET_DEVICE_CONFIG_HISTORY.replace('{id}', `${deviceId}`)}?${queryString}`,
      withCredentials
    )
    .then((res) => res.data);
};

export const getDevicesModels = (): Promise<any> =>
  api.get(GET_DEVICES_MODELS, withCredentials).then((res: any) => res.data);

export const createDevice = (data: any): Promise<any> =>
  api.post(CREATE_DEVICE, data, withCredentials).then((res: any) => res.data);

export const deleteDevice = (deviceId: number): Promise<any> =>
  api
    .delete(DELETE_DEVICE.replace('{id}', `${deviceId}`), withCredentials)
    .then((res: any) => res.data);

export const updateDevice = ({ data, deviceId }: any): Promise<any> =>
  api
    .put(UPDATE_DEVICE.replace('{id}', `${deviceId}`), data, withCredentials)
    .then((res: any) => res.data);

export const getDeviceConfig = (deviceId: number) =>
  api
    .get<DeviceConfigEntry>(GET_DEVICE_CONFIG.replace('{id}', `${deviceId}`), withCredentials)
    .then((res) => res.data);

export const addDevice = ({ serial }: any): Promise<any> =>
  api
    .post(ADD_DEVICE.replace('{serial}', `${serial}`), {}, withCredentials)
    .then((res: any) => res.data);
