/* eslint-disable no-unused-vars */
import { useContext, useEffect, useRef, useState } from 'react';
import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  CONFIGURATOR_DEVICE,
  DEVICE,
  DEVICE_ADD,
  DEVICE_EDIT,
  PATIENTS
} from '../../../constants/routes';
import { useDeviceDelete, useDeviceModelList, useDevicesList } from '../../hooks/api/useDevices';
import { useModal } from '../../hooks/api/useModal';
import ConfirmDeleteModal from '../../components/Modals/ConfirmDeleteModal';
import { TableLink } from 'adp-panel/components/Links/Links';
import Card from 'adp-panel/components/Card/Card';
import useUserData from '../../../hooks/useUserData';
import {
  DeviceExtendOptions,
  DevicesQueryParams,
  DevicesSortOptions
} from 'adp-panel/api/devices/device.types';
import { userHasPermissions } from 'adp-panel/utils/permissionUtils';
import { RoleEnum } from 'adp-panel/api/users/users.types';
import { FilterValue } from 'adp-panel/components/Filters/FilterBox.types';
import { find } from 'lodash';
import { SortDirs } from 'types';
import { DeviceEntry } from 'adp-panel/api/devices/device.types';
import CustomTable from 'adp-panel/components/Table/CustomTable';
import { ReactComponent as UserChangeSvg } from 'assets/user-change.svg';
import { ReactComponent as TrashSvg } from 'assets/trash-icon.svg';
import AssignDeviceModal from 'adp-panel/pages/Devices/Forms/AssignDeviceModal';
import CustomButton from 'components/Button/CustomButton';
import { Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const fieldFiltersAdmin: FilterValue[] = [
  { field: 'company', value: [], role: RoleEnum.superAdmin },
  { field: 'model', value: [] },
  { field: 'active', value: true }
];

const mapDeviceRows = (apiData: DeviceEntry[]) =>
  apiData.map((device) => ({
    id: device.id,
    serial: device.serial,
    bluetooth_id: device.bluetooth_id,
    model: device.model?.name,
    company: device.company,
    amputee: device.amputee,
    clinician: device.clinician,
    active_code: device?.active_code?.code
  }));

const DevicesList = () => {
  const { t } = useTranslation('devices');
  const [filters, setFilters] = useState<FilterValue[]>(fieldFiltersAdmin);
  const { rolesByName } = useUserData();
  const [searchTerm, setSearchTerm] = useState<any>('');
  const [selectedDevice, setSelectedDevice] = useState<number>();
  const navigate = useNavigate();
  const tableRef = useRef(null) as any;
  const modelFilter = find(filters, { field: 'model' });
  const [sortOptions, setSortOptions] = useState<{
    field: null | DevicesSortOptions;
    value: null | SortDirs;
  }>({
    field: null,
    value: null
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });

  const commonQueryParams: DevicesQueryParams = {
    search: searchTerm,
    ...(modelFilter && modelFilter.value.length > 0 && { model: modelFilter.value }),
    perpage: 10,
    page: paginationModel.page + 1,
    ...(sortOptions.field &&
      sortOptions.value && { sortby: sortOptions.field, sortdir: sortOptions.value })
  };

  const queryParamsDevices: DevicesQueryParams = {
    extend: [
      DeviceExtendOptions.model,
      DeviceExtendOptions.clinician,
      DeviceExtendOptions.amputee,
      DeviceExtendOptions.company,
      DeviceExtendOptions.pcbVersion,
      DeviceExtendOptions.firmawreVersion,
      DeviceExtendOptions.activeCode
    ],
    ...commonQueryParams
  };

  const {
    result: devicesData,
    total: totalDevices,
    isLoading: isLoadingDevices
  } = useDevicesList(queryParamsDevices);

  const isAdmin = userHasPermissions([RoleEnum.superAdmin], rolesByName);

  const { mutateAsync: deleteDevice, isLoading: isLoadingDelete } = useDeviceDelete();
  const {
    isOpen: isDeleteModalOpen,
    handleOpen: handleDeleteModalOpen,
    handleClose: handleDeleteModalClose
  } = useModal();
  const {
    isOpen: isDeviceAssignModalOpen,
    handleOpen: handleDeviceAssignModalOpen,
    handleClose: handleDeviceAssignModalClose
  } = useModal();

  const handleDelete = async (deviceId: number) => {
    setSelectedDevice(deviceId);
    handleDeleteModalOpen();
  };

  const handleEdit = async (deviceId: number) => {
    setSelectedDevice(deviceId);
    handleDeviceAssignModalOpen();
  };

  const deleteDev = async () => {
    if (selectedDevice) {
      await deleteDevice(selectedDevice);
      handleDeleteModalClose();
      tableRef.current.refresh();
    }
  };

  const handleConfigure = (serial: string, bluetooth_id: string, deviceId: string) => {
    navigate(CONFIGURATOR_DEVICE, { state: { serial, bluetoothId: bluetooth_id, deviceId } });
  };

  return (
    <DefaultLayout>
      <Card>
        {isDeviceAssignModalOpen && (
          <AssignDeviceModal
            isModalOpen={isDeviceAssignModalOpen}
            handleModalClose={handleDeviceAssignModalClose}
            deviceId={selectedDevice}
          />
        )}
        <ConfirmDeleteModal
          isModalOpen={isDeleteModalOpen}
          handleClose={handleDeleteModalClose}
          handleAccept={deleteDev}
          isLoading={isLoadingDelete}
          message={t(
            'devices:devices_list.delete_modal.message',
            "We don't delete the device, you just won't be attached to them anymore."
          )}
        />
        <div>
          <CustomTable
            actions={
              isAdmin && (
                <CustomButton color='light' onClick={() => navigate(DEVICE_ADD)}>
                  {t('devices:devices_list.buttons.add_new_device', 'Add new device')}
                </CustomButton>
              )
            }
            tableData={devicesData}
            tableHeader={t('devices:devices_list.table.header', 'Devices')}
            totalItems={totalDevices}
            isLoading={isLoadingDevices}
            columns={[
              {
                field: 'serial',
                headerName: t('devices:devices_list.table.columns.serial', 'Serial'),
                flex: 1,
                ...(isAdmin && {
                  renderCell: (params) => {
                    const { id, serial } = params.row;
                    return (
                      <td>
                        <TableLink to={`${DEVICE}/${id}`}>{serial}</TableLink>
                      </td>
                    );
                  }
                })
              },
              {
                field: 'bluetooth_id',
                headerName: t('devices:devices_list.table.columns.bluetooth_id', 'Bluetooth ID'),
                flex: 1,
                sortable: false,
                renderCell: (params) => {
                  const { bluetooth_id } = params.row;
                  return <Chip size='small' label={bluetooth_id} />;
                }
              },
              {
                field: 'model',
                headerName: t('devices:devices_list.table.columns.model', 'Model'),
                flex: 1,
                sortable: false
              },
              {
                field: 'amputee',
                headerName: t('devices:devices_list.table.columns.patient', 'Patient'),
                flex: 1,
                renderCell: (params) => {
                  const { amputee } = params.row;
                  return (
                    <td>
                      {amputee?.id ? (
                        <TableLink to={`${PATIENTS}/${amputee?.id}`}>{amputee?.name}</TableLink>
                      ) : (
                        '-'
                      )}
                    </td>
                  );
                }
              },
              {
                field: 'active_code',
                headerName: t('devices:devices_list.table.columns.active_code', 'Active code'),
                flex: 1
              },
              {
                field: 'actions',
                type: 'actions',
                flex: 1,
                headerName: t('devices:devices_list.table.columns.actions', 'Actions'),
                getActions: (params) => {
                  const { serial, bluetooth_id, id } = params.row;

                  const actionButtons = isAdmin
                    ? [
                        <CustomButton
                          Icon={EditIcon}
                          onClick={() => navigate(DEVICE_EDIT, { state: { detail: id } })}
                          data-testid='button-edit'
                        />,
                        <CustomButton
                          Icon={TrashSvg}
                          color='light'
                          data-testid='button-delete'
                          onClick={handleDeleteModalOpen}
                        />
                      ]
                    : [
                        <CustomButton
                          Icon={UserChangeSvg}
                          color='light'
                          onClick={() => handleEdit(id)}
                          data-testid='button-edit'
                        />,
                        <CustomButton
                          Icon={TrashSvg}
                          color='light'
                          data-testid='button-delete'
                          onClick={handleDeleteModalOpen}
                        />
                      ];

                  return actionButtons;
                }
              }
            ]}
            columnVisibilityModel={{
              active_code: isAdmin
            }}
            mapTableData={mapDeviceRows}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        </div>
      </Card>
    </DefaultLayout>
  );
};

export default DevicesList;
