import React from 'react';
import IconsSidebar from 'configurator/components/organisms/IconsSidebar.js/IconsSidebar';
import DefaultLayout from 'configurator/layouts/DefaultLayout';
import CustomPageHeader from 'configurator/components/atoms/Typography/CustomHeader';

const LayoutSwitcher = ({
  component: Component,
  header = null,
  handleUndo = null,
  isUnsaved = false,
  children = null,
  showSidebar = true
}: any) => {
  return (
    <DefaultLayout>
      {showSidebar && <IconsSidebar undoFunc={handleUndo} unsaved={isUnsaved} />}
      {header && <CustomPageHeader header={header} />}
      {children ? children : <Component />}
    </DefaultLayout>
  );
};

export default LayoutSwitcher;
