import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  checkMfaStatus,
  fetchUserData,
  loginUserSPA,
  sendMfaCode,
  setMfaStatus,
  verifyMfaCode,
  verifyPhone
} from 'adp-panel/api/authentication/authentication';
import { MfaOptions, MfaRequire } from 'adp-panel/api/authentication/authentication.types';
import { ME_QUERY_KEY } from 'adp-panel/hooks/api/useUsers';
import { registerUser } from 'configurator/api/authentication/authentication';
import { REDIRECT } from 'constants/routes';
import CryptoJS from 'crypto-js';
import { NotificationFactory } from 'lib/NotificationFactory';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from 'reducers/authStore';
import { mapErrorMessage } from 'utils/notifications';
import useRefreshToken from '../adp-panel/hoc/useRefreshToken';
import useLogout from './useLogout';

export const USER_QUERY_MFA = 'mfa';
export const useAuthentication = (blockRedirect = false) => {
  const broadcast = new BroadcastChannel('login');
  const { i18n } = useTranslation();
  const [is2FaPage, setIs2FaPage] = useState(false);
  const { mfaCodeRequire, mfaData } = useAuthStore((state) => ({
    mfaCodeRequire: state.mfaCodeRequire,
    mfaData: state.mfa
  }));
  const { refreshToken } = useRefreshToken();
  const { mfaLogout, isLoading: mfaLogoutLoading } = useLogout();
  const { isLoading: isLoadingSendCode, mutate: sendCode } = useMutation(sendMfaCode);
  const data: MfaRequire = {
    require: false,
    channel: null
  };
  const [isLoadingAuthentication, setIsLoadingAuthentication] = useState(false);

  const {
    isLoading: isLoadingVerify,
    isError: isErrorVerify,
    mutate: verifyCode
  } = useMutation(verifyMfaCode, {
    onSuccess: async (result, variables) => {
      setIsLoadingAuthentication(true);
      Promise.all([refreshToken, fetchUserData]).finally(() => {
        const hash = localStorage.getItem('hash');
        if (hash) {
          localStorage.setItem(hash, result.mfa_token);
        }
        mfaCodeRequire(data);
        if (variables?.blockRedirect !== true) {
          broadcast.postMessage({ typ: 'LOG_IN' });
        }
        setIsLoadingAuthentication(false);
      });
    },
    onError() {
      NotificationFactory.errorNotification(
        'We\'re sorry, but the MFA code you entered is no longer valid or invalid. To continue, please click on "Resend code" to generate a new MFA code that will be valid for 15 minutes.'
      );
    }
  });

  const setTokenToStorage = (userData: any): Promise<void> => {
    return new Promise((resolve) => {
      const hash = CryptoJS.SHA1(String(userData.email)).toString(CryptoJS.enc.Base64);
      localStorage.setItem('hash', hash);
      resolve();
    });
  };

  const {
    isLoading: isLoadingLogin,
    mutate: login,
    data: userData,
    error: errorLogin
  } = useMutation(loginUserSPA, {
    onSuccess: async (userData, variables) => {
      setIsLoadingAuthentication(true);
      await i18n.changeLanguage(userData.language);
      setTokenToStorage(userData).then(async () => {
        try {
          await fetchUserData();
          refreshToken().finally(() => {
            if (variables?.blockRedirect !== true) {
              broadcast.postMessage({ typ: 'LOG_IN' });
            }
            setIsLoadingAuthentication(false);
          });
        } catch (error: any) {
          if (error.isAxiosError) {
            if (error.response.status === 401 && error.response?.data?.method) {
              sendCode({
                channel: error.response.data.method
              });

              mfaCodeRequire({
                require: true,
                channel: error.response.data.method
              });

              setIs2FaPage(true);
            }

            if (error.response.status === 422) {
              NotificationFactory.errorNotification('Invalid email or password');
            }
          }
          setIsLoadingAuthentication(false);
        }
      });
    },
    onError(error: any) {
      if (error?.isAxiosError && error?.response?.status === 403) {
        NotificationFactory.errorNotification('This account is a temporary lock');
        return;
      }
      mapErrorMessage(error);
    }
  });

  const handleLogout = () => {
    mfaLogout();
  };

  const reSendCode = (method: any = undefined) => {
    sendCode({
      channel: method ?? mfaData.channel ?? MfaOptions.email
    });
  };

  useEffect(() => {
    broadcast.onmessage = () => {
      window.location.assign(REDIRECT);
    };
  }, []);

  return {
    isLoading:
      isLoadingSendCode ||
      isLoadingLogin ||
      isLoadingVerify ||
      mfaLogoutLoading ||
      isLoadingAuthentication,
    reSendCode,
    is2FaPage,
    login,
    verifyCode,
    isErrorVerify,
    mfaData,
    handleLogout,
    userData,
    errorLogin
  };
};

export const useMfaUpdate = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(setMfaStatus, {
    onSuccess: (successData) => {
      queryClient.setQueryData([USER_QUERY_MFA], successData);
      queryClient.invalidateQueries({ queryKey: [ME_QUERY_KEY] });
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useVerifyPhone = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(verifyPhone, {
    onSuccess() {
      NotificationFactory.successNotification('Phone number verified');
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useMfaMethod = () => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [USER_QUERY_MFA],
    checkMfaStatus,
    {
      retry: 0,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onError(error) {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data ? data : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useRegisterUser = () => {
  const { data, mutateAsync, isLoading, isError, error, isSuccess } = useMutation(registerUser, {
    onError: (err) => {
      mapErrorMessage(err);
    }
  });

  return {
    mutateAsync,
    data,
    isLoading,
    isError,
    error,
    isSuccess
  };
};
