/* eslint-disable no-unused-vars */
import i18next from 'i18next';
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { debounce, mapValues, pick } from 'lodash';
import toast from 'react-hot-toast';
import { Alert, Checkbox } from '@mui/material';
import {
  gripsGroupsOptionsMap,
  gripsGroupsOptionsReversedMap
} from 'configurator/utils/definesLocal';
import { gripsImagesMap } from 'configurator/utils/gripsImages';
import {
  postCurrentGrip,
  postJointTargetPosition
} from 'configurator/bluetooth-handler/bluetoothFunctions';
import { GRIPS, HISTORY_EVENTS } from 'configurator/consts/consts';
import DropdownImg from 'configurator/components/atoms/Dropdown/DropdownImg';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import { timeoutCommandCustom } from 'configurator/utils/funcs';
import { useReplayStore } from 'configurator/reducers/replayStore';
import TelemetryController from 'configurator/bluetooth-handler/telemetryController';
import { getCurrentConfigSelector } from 'configurator/reducers/helpers/selectors';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { defaultConfig } from 'configurator/consts/deviceConfig/deviceConfig';
import { useTranslation } from 'react-i18next';
import { Grips } from '../../../bluetooth/Bluetooth/Grips';
import {
  AllowText,
  CardsContainer,
  GripImage,
  GripWrapper,
  HandMovementCheckboxWrapper,
  StyledError,
  StyledPositionAdjuster,
  Viewer,
  ViewersWrapper
} from './styled';
import useCanAccess from '../../hooks/useCanAccess';
import useWatch from 'configurator/hooks/useWatch';
import Card from 'adp-panel/components/Card/Card';

const FALLBACK_POSITION = [0, 0, 0, 0, 0];

const setHandGripPositions = async (gripValues: number[]) => {
  if (gripValues) {
    await postJointTargetPosition(0, gripValues[0]);
    await postJointTargetPosition(1, gripValues[1]);
    await postJointTargetPosition(2, gripValues[2]);
    await postJointTargetPosition(3, gripValues[3]);
    await postJointTargetPosition(4, gripValues[4]);
  }
};

const sendFingersThrottled = debounce(setHandGripPositions, 100);

const sendFingers = async (value: any, index: any, { index: fingerIndex }: any) => {
  await postJointTargetPosition(fingerIndex, value);
};

const sendIndividualFingerThrottled = debounce(sendFingers, 100);

const normalizeConfig = (value: any) => {
  const isNotInitialized = (initial: any, limit: any) => initial === false || limit === false;
  const mappedObject = mapValues(value, (_value, key) => {
    const initial = value[`${key}`]?.initial;
    const limit = value[`${key}`]?.limit;
    if (isNotInitialized(initial, limit)) {
      // @ts-ignore
      return defaultConfig.gripsPositions[`${key}`];
    }
    return value[`${key}`];
  });

  return mappedObject;
};

enum SITES {
  initial,
  limit
}

const extractFingerChange = (event: any) => {
  const after = event.data.diff.common.after.gripsPositions;
  const afterGrip = Object.keys(after);
  let before = event.data.diff.common.before.gripsPositions;
  before = pick(before, afterGrip);

  const afterGripValues = after[`${afterGrip[0]}`];
  const beforeGripValues = before[`${afterGrip[0]}`];

  const initialDifferenceIndex = afterGripValues.initial.findIndex(
    (item: any, index: any) => item !== beforeGripValues.initial[index]
  );
  const limitDifferenceIndex = afterGripValues.limit.findIndex(
    (item: any, index: any) => item !== beforeGripValues.limit[index]
  );

  let index;
  let value;

  if (initialDifferenceIndex > -1) {
    index = initialDifferenceIndex;
    value = beforeGripValues.initial[index];
  } else {
    index = limitDifferenceIndex;
    value = beforeGripValues.limit[index];
  }

  return { value, index, site: initialDifferenceIndex ? SITES.initial : SITES.limit };
};

const GripsConfigurationComponent = () => {
  const { t } = useTranslation();
  const { gripsPositions } = useConfigStore(getCurrentConfigSelector);
  const {
    handMovementAllowed,
    currentGrip,
    setItemConfigStore,
    resetGripPositions,
    setConfigProperty,
    addConfigHistory
  } = useConfigStore((state: any) => ({
    handMovementAllowed: state.handMovementAllowed,
    currentGrip: state.currentGrip,
    setItemConfigStore: state.setItemConfigStore,
    resetGripPositions: state.resetGripPositions,
    setConfigProperty: state.setConfigProperty,
    addConfigHistory: state.addConfigHistory
  }));
  const deviceConnected = useDeviceInfoStore((state: any) => state.connected);
  const [grips, setGrips] = useState<any>(GRIPS);
  const [disableInput, setDisableInput] = useState(true);
  const [selectedGrip, setSelectedGrip] = useState(Grips.kGripTypeUnknown);
  const restoreRecentChannel = new BroadcastChannel('handleRestoreRecent');
  const undoChannel = new BroadcastChannel('undo');
  const [restoreReceived, setRestoreReceived] = useState(false);
  useWatch(TelemetryController, ['prosthesisGrip', 'gripInTransition']);
  const [values, setValues] = useState(() => {
    const initial = {};
    for (const key in Grips) {
      if (Object.hasOwnProperty.call(Grips, key)) {
        // @ts-ignore
        initial[Grips[key]] = {
          initial: [0, 0, 0, 0, 0],
          limit: [0, 0, 0, 0, 0]
        };
      }
    }
    return initial;
  });
  const { canAccess } = useCanAccess({ action: 'gripsConfiguration', resource: 'grips' });
  const { enabled: replayIsEnabled } = useReplayStore();
  const gripInTransition = handMovementAllowed && TelemetryController.gripInTransition;

  useEffect(() => {
    if (
      (TelemetryController.prosthesisGrip || TelemetryController.prosthesisGrip === 0) &&
      handMovementAllowed &&
      TelemetryController.prosthesisGrip !== 255
    ) {
      setItemConfigStore('currentGrip', TelemetryController.prosthesisGrip);
    }
  }, [TelemetryController.prosthesisGrip]);

  // Update grips positions from store
  useEffect(() => {
    if (Object.keys(gripsPositions).length > 0) {
      setValues(normalizeConfig(gripsPositions));
      setGrips(
        Object.keys(gripsPositions)
          .map((position) => parseInt(position, 10))
          .filter(
            (position) =>
              position !== Grips.kGripCamera &&
              position !== Grips.kGripTypeUnknown &&
              !Number.isNaN(position)
          )
      );
    }
  }, [gripsPositions]);

  // Disable input if grip is undefined
  useEffect(() => {
    const setHandPosition = async () => {
      let breakLoop = false;
      const waitForTransitionEnd = async () => {
        while (TelemetryController.gripInTransition && !breakLoop) {
          await delay(100);
        }
        return true;
      };
      await postCurrentGrip(currentGrip);
      await delay(300);
      await timeoutCommandCustom(() => waitForTransitionEnd(), 5000);
      await setHandGripPositions(gripsPositions[currentGrip]?.initial ?? FALLBACK_POSITION);
      breakLoop = true;
    };

    if (currentGrip || currentGrip === 0) {
      if (currentGrip !== Grips.kGripTypeUnknown) {
        setDisableInput(false);
      }
      setSelectedGrip(currentGrip);
      if (handMovementAllowed) {
        setHandPosition();
      }
    }
  }, [currentGrip, handMovementAllowed]);

  useEffect(() => {
    restoreRecentChannel.onmessage = () => {
      setRestoreReceived(true);
    };
    if (handMovementAllowed) {
      sendFingersThrottled(gripsPositions[currentGrip]?.initial ?? FALLBACK_POSITION);
      setRestoreReceived(false);
      undoChannel.onmessage = (message) => {
        const { value, index } = extractFingerChange(message);
        sendIndividualFingerThrottled(value, null, { index });
      };
    }

    return () => {
      restoreRecentChannel.close();
      undoChannel.close();
    };
  }, [handMovementAllowed, restoreReceived]);

  const handleOptions = async (option: any) => {
    const newGrip: any = gripsGroupsOptionsReversedMap.get(option);
    setItemConfigStore('currentGrip', newGrip);
  };

  const handleSliderChange = async (
    value: any,
    index: any,
    { index: fingerIndex, sliderType, min, max }: any
  ) => {
    let valueSanitized = value;
    if (typeof valueSanitized === 'string') {
      valueSanitized = parseInt(value, 10);
      if (Number.isNaN(valueSanitized)) valueSanitized = min;
    }
    if (value > max) {
      valueSanitized = max;
    }
    if (value < min) {
      valueSanitized = min;
    }
    // @ts-ignore
    let newInitial = values[selectedGrip].initial;
    // @ts-ignore
    let newLimit = values[selectedGrip].limit;
    if (sliderType === 'primary') {
      newInitial = newInitial.map((v: any, i: any) => {
        if (i === fingerIndex) v = valueSanitized;
        return v;
      });
    } else {
      newLimit = newLimit.map((v: any, i: any) => {
        if (i === fingerIndex) v = valueSanitized;
        return v;
      });
    }
    const newGripValues = {
      initial: newInitial,
      limit: newLimit
    };
    setValues((prev) => ({
      ...prev,
      [selectedGrip]: newGripValues
    }));
  };

  const handleSliderChangeThrottled = debounce(handleSliderChange, 10);

  const handleOnAfterChange = async (...args: any) => {
    const [newValue, _, { index, sliderType }] = args;
    // @ts-ignore
    const initialValue = values[selectedGrip].initial[index];
    // @ts-ignore
    const limitValue = values[selectedGrip].limit[index];
    const errorMessage = t(
      'configurator:component.grips_config.error_message',
      "Beginning position is greater than the end position, finger won't be able to move back to beginning position"
    );
    if (sliderType === 'primary') {
      if (newValue > limitValue) {
        toast.error(errorMessage, { id: 'gripsConfigurationPrimary' });
      }
    } else if (newValue < initialValue) {
      toast.error(errorMessage, { id: 'gripsConfigurationSecondary' });
    }
    addConfigHistory(
      HISTORY_EVENTS.gripsConfiguration,
      setConfigProperty('gripsPositions', values)
    );
  };

  return (
    <CardsContainer>
      <GripWrapper as={Card} padding='30px'>
        <StyledPositionAdjuster
          values={{
            // @ts-ignore
            columnPrimary: disableInput ? [0, 0, 0, 0, 0] : values[selectedGrip].initial,
            // @ts-ignore
            columnSecondary: disableInput ? [0, 0, 0, 0, 0] : values[selectedGrip].limit
          }}
          handleSliderChange={(...args: Parameters<typeof handleSliderChangeThrottled>) => {
            handleSliderChangeThrottled(...args);
            if (handMovementAllowed) {
              sendIndividualFingerThrottled(...args);
            }
          }}
          handleInputChange={handleSliderChange}
          handleOnAfterChange={handleOnAfterChange}
          handleOnAfterChangeInput={(...args: Parameters<typeof handleSliderChangeThrottled>) => {
            handleOnAfterChange(...args);
            if (handMovementAllowed) {
              sendIndividualFingerThrottled(...args);
            }
          }}
          rows={[
            t('configurator:component.value_mapping.grips_positions.thumb', 'Thumb'),
            t('configurator:component.value_mapping.grips_positions.index', 'Index'),
            t('configurator:component.value_mapping.grips_positions.middle', 'Middle'),
            t('configurator:component.value_mapping.grips_positions.ring', 'Ring'),
            t('configurator:component.value_mapping.grips_positions.pinky', 'Pinky')
          ]}
          columns={[
            t('configurator:component.grips_config.beginning_position', 'Beginning position'),
            t('configurator:component.grips_config.end_position', 'End position')
          ]}
          // @ts-ignore
          hideButton
          limits={[
            { min: 0, max: 1000 },
            { min: 0, max: 1000 },
            { min: 0, max: 1000 },
            { min: 0, max: 1000 },
            { min: 0, max: 1000 }
          ]}
          disableInput={disableInput || replayIsEnabled || !canAccess || gripInTransition}
          buttonText={t(
            'configurator:component.grips_config.restore_button',
            'Restore default grip configuration'
          )}
          handleActionButton={() => {
            resetGripPositions(currentGrip);
            setRestoreReceived(true);
          }}
        />
      </GripWrapper>
      <div>
        <ViewersWrapper as={Card} padding='30px'>
          <DropdownImg
            options={grips.map((grip: any) => ({
              value: gripsGroupsOptionsMap.get(grip),
              img: gripsImagesMap.get(grip)
            }))}
            disabled={gripInTransition}
            selected={{
              value: gripsGroupsOptionsMap.get(selectedGrip),
              img: gripsImagesMap.get(selectedGrip)
            }}
            onChange={(option: any) => handleOptions(option)}
            showImg={false}
            label={t('configurator:component.grips_config.current_grip', 'Current grip')}
          />
          <Viewer>
            <GripImage src={gripsImagesMap.get(selectedGrip)} alt='Graph viewer' />
          </Viewer>
          {gripInTransition && (
            <Alert severity='info'>
              {t(
                'configurator:component.grips_config.grip_in_transition',
                'Grip in transition, please wait.'
              )}
            </Alert>
          )}
          {deviceConnected && !disableInput && !gripInTransition && (
            <HandMovementCheckboxWrapper>
              <div>
                <AllowText>
                  {t(
                    'configurator:component.grips_config.allow_hand_movement',
                    'Allow hand movement'
                  )}
                </AllowText>
                <Checkbox
                  onClick={() => setItemConfigStore('handMovementAllowed', !handMovementAllowed)}
                  id='movement'
                  name='movement'
                  checked={handMovementAllowed}
                />
              </div>
              <StyledError show={handMovementAllowed}>
                {t(
                  'configurator:component.grips_config.safe_position_warning',
                  'Make sure hand is in safe position!'
                )}
              </StyledError>
            </HandMovementCheckboxWrapper>
          )}
        </ViewersWrapper>
      </div>
    </CardsContainer>
  );
};

export default GripsConfigurationComponent;
