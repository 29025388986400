import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DropdownImg from 'configurator/components/atoms/Dropdown/DropdownImg';
import {
  gripsGroupsOptionsMap,
  gripsGroupsOptionsReversedMap
} from 'configurator/utils/definesLocal';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import { Grips } from '../../../../bluetooth/Bluetooth/Grips';
import Card from 'adp-panel/components/Card/Card';
import { Checkbox } from '@mui/material';
import EllipseSequential from 'assets/ellipse-sequential.svg';
import EllipseSequential2 from 'assets/ellipse-sequential-2.svg';
import {
  arrowsPositionsPrimary,
  arrowsPositionsSecondary,
  arrowWidth,
  gripSwitchIndicatorPrimary,
  gripSwitchIndicatorSecondary,
  positionsDropdowns
} from './positions';
import { GripSwitchingModeIndicator } from '../GripSwitchingModeIndicator/GripSwitchingModeIndicator';

const DropdownsWrapper = styled.div`
  width: 100%;
  padding-bottom: 100%;
  position: relative;
`;

const GripWrapper = styled.div`
  width: 200px;
`;

const HeaderSecondary = styled.h4<{ active?: any; show?: any }>`
  display: flex;
  align-items: center;
  ${TextS};
  text-align: center;
  margin-right: ${({ show }) => (show ? '10px' : '0')};
  opacity: ${({ active }) => (active ? '1' : '0.5')};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

type GraphChooserTreeProps = {
  id?: string;
  grips: Grips[];
  values: Grips[];
  nonOpposed?: boolean;
  onChange: Function;
  opened: number | null;
  updateOpened?: Function;
  indexes: number[];
  gripsImgs: any;
  disabled?: boolean;
};

const GripsChooser2 = ({
  grips,
  values,
  nonOpposed = false,
  onChange,
  updateOpened = () => false,
  opened = null,
  indexes = [0],
  gripsImgs,
  disabled,
  ...props
}: GraphChooserTreeProps) => {
  const { t } = useTranslation();
  const [enabledColumns, setEnabledColumns] = useState([true, false, false, false, false]);
  const [visibleCheckboxes, setVisibleCheckboxes] = useState([true, false, false, false, false]);
  const [currentlyOpen, setCurrentlyOpen] = useState<null | number>(null);

  const updateGrips = (option, gripOrder, isNonOpposed) => {
    onChange(gripsGroupsOptionsReversedMap.get(option), gripOrder, isNonOpposed);
  };

  const updateEnabledColumns = (index) => {
    const newEnabledColumns = enabledColumns.map((isColumnEnabled, localIndex) => {
      if (index === localIndex) {
        isColumnEnabled = !isColumnEnabled;
        if (isColumnEnabled === false) {
          updateGrips('configurator:constants.grips.disabled', index, nonOpposed);
        } else {
          updateGrips(gripsGroupsOptionsMap.get(grips[0]), index, nonOpposed);
        }
      }
      return isColumnEnabled;
    });
    setEnabledColumns(newEnabledColumns);
  };

  useEffect(() => {
    const gripsSequence = values;
    const updatedEnabledColumns = gripsSequence.map((grip) => {
      if (grip === Grips.kGripTypeUnknown) {
        return false;
      }
      return true;
    });
    setEnabledColumns(updatedEnabledColumns);
  }, [values]);

  useEffect(() => {
    const newVisibleCheckboxes = Array(5).fill(false);
    const lastIndex = values.findIndex(
      (value, index) =>
        value === Grips.kGripTypeUnknown && value[index - 1] !== Grips.kGripTypeUnknown
    );
    if (lastIndex !== 1) {
      newVisibleCheckboxes[lastIndex] = true;
      newVisibleCheckboxes[lastIndex - 1] = true;
    } else {
      newVisibleCheckboxes[1] = true;
    }
    setVisibleCheckboxes(newVisibleCheckboxes);
  }, [enabledColumns]);

  const handleOpen = (index) => {
    setCurrentlyOpen(index);
  };

  return (
    <Card
      {...props}
      style={{ minWidth: '920px', maxWidth: '1050px', padding: '5%', margin: '0 auto' }}>
      <DropdownsWrapper>
        {arrowsPositionsPrimary.map((position) => (
          <img
            src={EllipseSequential}
            style={{ width: arrowWidth, position: 'absolute', ...position }}
          />
        ))}
        {arrowsPositionsSecondary.map((position) => (
          <img
            src={EllipseSequential2}
            style={{ width: arrowWidth, position: 'absolute', ...position }}
          />
        ))}
        {gripSwitchIndicatorPrimary.map((position) => (
          <GripSwitchingModeIndicator style={{ position: 'absolute', ...position }} />
        ))}
        {gripSwitchIndicatorSecondary.map((position) => (
          <GripSwitchingModeIndicator
            style={{ position: 'absolute', ...position }}
            primary={false}
          />
        ))}
        {indexes.map((chooseGripsIndex, localIndex) => (
          <div
            style={{
              position: 'absolute',
              ...positionsDropdowns[localIndex],
              zIndex: localIndex === currentlyOpen ? '999' : 'auto'
            }}>
            <GripWrapper>
              <CheckboxWrapper>
                <Checkbox
                  onClick={() => updateEnabledColumns(localIndex)}
                  checked={enabledColumns[localIndex]}
                  disabled={!visibleCheckboxes[localIndex]}
                  id={`checkbox${localIndex}`}
                  name={`checkbox${localIndex}`}
                  sx={{ display: visibleCheckboxes[localIndex] ? 'block' : 'none' }}
                />
                <HeaderSecondary
                  active={enabledColumns[localIndex]}
                  show={visibleCheckboxes[localIndex]}>
                  {t('prosthesis_settings.grip', { ns: 'configurator' })} {localIndex + 1}
                </HeaderSecondary>
              </CheckboxWrapper>
              <div style={{ opacity: enabledColumns[localIndex] ? '1' : '0.5' }}>
                <DropdownImg
                  onOpen={() => handleOpen(localIndex)}
                  disabled={disabled}
                  active={enabledColumns[localIndex]}
                  options={grips.map((grip) => ({
                    value: gripsGroupsOptionsMap.get(grip),
                    img: gripsImgs.get(grip)
                  }))}
                  selected={{
                    value: gripsGroupsOptionsMap.get(values[localIndex]),
                    img: gripsImgs.get(values[localIndex])
                  }}
                  onChange={(option) => updateGrips(option, localIndex, nonOpposed)}
                  isOpen={opened === chooseGripsIndex}
                />
              </div>
            </GripWrapper>
          </div>
        ))}
      </DropdownsWrapper>
    </Card>
  );
};

export default GripsChooser2;
