/* eslint-disable prefer-destructuring */
import React, { useState, useMemo, useEffect } from 'react';
import { initialState } from 'configurator/reducers/helpers/initialState';
import {
  userFeedbackTypeMap,
  userFeedbackTypeReversedMap,
  userFeedbackTypePcbV4Map,
  userFeedbackTypePcbV4ReversedMap
} from 'configurator/utils/definesLocal';
import { useTranslation } from 'react-i18next';
import { OptionsWithSlider } from '../../../components/molecules/Sliders/Sliders';

const BuzzingVolumeSettings = ({
  addHistoryProsthesis,
  buzzingVolumeSettings,
  userFeedbackType,
  replayIsEnabled,
  setConfigProperty,
  supportNewFeedbackType
}: any) => {
  const { t } = useTranslation();
  const [buzzingVolumeLocal, setBuzzingVolumeLocal] = useState<any>(
    // @ts-ignore
    initialState.config.buzzingVolumeSettings[0]
  );

  const handleBuzzingVolumeSlider = (value: any) => {
    addHistoryProsthesis(setConfigProperty('buzzingVolumeSettings', [value]));
  };

  const handleUserFeedbackType = (value: any) => {
    addHistoryProsthesis(setConfigProperty('userFeedbackType', [value]));
  };

  useEffect(() => {
    if (buzzingVolumeSettings) {
      setBuzzingVolumeLocal(buzzingVolumeSettings[0]);
    }
  }, [buzzingVolumeSettings]);

  const calculatePercentageValue = (value: number) => Math.round((value / 255) * 100);

  const BuzzingVolumeSettingsNewVersion = useMemo(
    () => (
      <OptionsWithSlider
        header={t('configurator:component.buzzing_volume_settings.header', 'Buzzing settings')}
        toggleOptions={Array.from(userFeedbackTypePcbV4Map, ([, value]) => value)}
        disabled={replayIsEnabled}
        toggleValue={userFeedbackTypePcbV4Map.get(userFeedbackType[0])}
        sliderValue={buzzingVolumeLocal}
        handleSliderChange={(e: any, value: any) => setBuzzingVolumeLocal(value)}
        sliderLimits={{ min: 0, max: 255 }}
        sliderLabel={t('configurator:component.buzzing_volume_settings.slider_label', 'Value:')}
        handleToggleChange={(value: any) =>
          handleUserFeedbackType(userFeedbackTypePcbV4ReversedMap.get(value))
        }
        handleSliderOnAfterChange={(...args: any) => handleBuzzingVolumeSlider(args[1])}
        description={t(
          'configurator:component.buzzing_volume_settings.description',
          'Set type of user feedback indicator and volume of the buzzer'
        )}
        tooltip={t('configurator:component.buzzing_volume_settings.tooltip', 'Hello')}
        valueToDisplay={`${calculatePercentageValue(buzzingVolumeLocal)}%`}
      />
    ),
    [userFeedbackType, buzzingVolumeLocal, buzzingVolumeSettings]
  );

  const BuzzingVolumeSettings = useMemo(
    () => (
      <OptionsWithSlider
        header={t('configurator:component.buzzing_volume_settings.header', 'Buzzing settings')}
        toggleOptions={Array.from(userFeedbackTypeMap, ([, value]) => value)}
        disabled={replayIsEnabled}
        toggleValue={userFeedbackTypeMap.get(userFeedbackType[0])}
        sliderValue={buzzingVolumeLocal}
        handleSliderChange={(e: any, value: any) => setBuzzingVolumeLocal(value)}
        sliderLimits={{ min: 0, max: 255 }}
        sliderLabel={t('configurator:component.buzzing_volume_settings.slider_label', 'Value:')}
        handleToggleChange={(value: any) =>
          handleUserFeedbackType(userFeedbackTypeReversedMap.get(value))
        }
        handleSliderOnAfterChange={(...args: any) => handleBuzzingVolumeSlider(args[1])}
        description={t(
          'configurator:component.buzzing_volume_settings.description',
          'Set type of user feedback indicator and volume of the buzzer'
        )}
        tooltip={t('configurator:component.buzzing_volume_settings.tooltip', 'Hello')}
        valueToDisplay={`${calculatePercentageValue(buzzingVolumeLocal)}%`}
      />
    ),
    [userFeedbackType, buzzingVolumeLocal, buzzingVolumeSettings]
  );

  return supportNewFeedbackType ? BuzzingVolumeSettingsNewVersion : BuzzingVolumeSettings;
};

export default BuzzingVolumeSettings;
