import { AudioMime } from 'adp-panel/constants/mimeTypes';
import { CustomTicketIcon } from 'components/CustomIcon/CustomIcon';
import React, { useRef } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

interface AttachmentHandlerProps {
  maxFileSize?: number; // Maximum file size in bytes
  allowedFileTypes?: string[]; // List of allowed MIME types
  filePreview: boolean;
  onFilesChange?: (files: UploadFileInfo[]) => void;
  attachments: UploadFileInfo[];
}

export type UploadFileStatus = 'Initial' | 'Selected' | 'Uploading' | 'Uploaded' | 'Failed';

export interface UploadFileInfo {
  uid: string;
  name: string;
  extension?: string;
  size?: number;
  validationErrors?: Array<string>;
  status: UploadFileStatus;
  progress: number;
  getRawFile?: () => File;
}

const AttachmentHandler: React.FC<AttachmentHandlerProps> = ({
  maxFileSize = 3000000,
  allowedFileTypes = [
    'image/*',
    'application/pdf',
    'application/msword',
    'text/plain',
    ...AudioMime
  ],
  onFilesChange,
  filePreview,
  attachments
}) => {
  const { t } = useTranslation();
  const imageInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const validateFiles = (files: FileList): UploadFileInfo[] => {
    const validatedFiles: UploadFileInfo[] = [];
    for (const file of Array.from(files)) {
      const errors: string[] = [];
      if (file.size > maxFileSize) {
        errors.push(
          t('common:validation.file_size_exceeds_limit', { size: maxFileSize / 1000000 })
        );
      }
      if (!allowedFileTypes.some((type) => file.type.match(type))) {
        errors.push(t('common:validation.file_type_not_allowed', { type: file.type }));
      }

      validatedFiles.push({
        uid: uuidv4(),
        name: file.name,
        extension: file.name.split('.').pop(),
        size: file.size,
        validationErrors: errors.length ? errors : undefined,
        status: errors.length ? 'Failed' : 'Selected',
        progress: 0,
        getRawFile: () => file
      });
    }
    return validatedFiles;
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const validFiles = validateFiles(files);
      onFilesChange?.([...attachments, ...validFiles]);
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const validFiles = validateFiles(files);
      onFilesChange?.([...attachments, ...validFiles]);
    }
  };

  const triggerImageUpload = () => imageInputRef.current?.click();
  const triggerFileUpload = () => fileInputRef.current?.click();

  return (
    <>
      <InputAdornment position='end'>
        <IconButton onClick={triggerImageUpload}>
          <CustomTicketIcon name='add-image' />
        </IconButton>
        <input
          type='file'
          ref={imageInputRef}
          style={{ display: 'none' }}
          accept='image/*'
          multiple
          onChange={handleImageUpload}
        />
      </InputAdornment>

      <InputAdornment position='end'>
        <IconButton onClick={triggerFileUpload}>
          <CustomTicketIcon name='add-file' />
        </IconButton>
        <input
          type='file'
          ref={fileInputRef}
          style={{ display: 'none' }}
          multiple
          onChange={handleFileUpload}
        />
      </InputAdornment>

      {filePreview && <div>todo</div>}
    </>
  );
};

export default AttachmentHandler;
