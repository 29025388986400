import { Modal } from '@mui/material';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import CustomButton from 'components/Button/CustomButton';
import { FormModalCheckboxesList, FormWrapper } from 'components/FormFields/commonStyles';
import FormComboBox, { clinicianOption } from 'components/FormFields/FormComboBox';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { myTheme } from 'theme/theme';
import FormButtonsWrapper from '../FormInput/FormButtonsWrapper';
import { LoadingButton } from '@mui/lab';
import { ReactComponent as PlusSvg } from 'assets/plus.svg';
import { ReactComponent as CrossSvg } from 'assets/cross.svg';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalStyle } from './styles';
import FormCheckbox from 'components/FormFields/FormCheckbox';

const CLINICIANS = [
  {
    id: 2,
    nameFull: 'John Doe (hubert@aetherbiomedical.com)',
    name: 'John Doe',
    email: 'hubert@aetherbiomedical.com'
  },
  {
    id: 3,
    nameFull: 'Mike Furry (hello@aetherbiomedical.com)',
    name: 'Mike Furry',
    email: 'hubert@aetherbiomedical.com'
  },
  {
    id: 4,
    nameFull: 'Klap Tap (hubert@aetherbiomedical.com)',
    name: 'Klap Tap',
    email: 'hubert@aetherbiomedical.com'
  },
  {
    id: 5,
    nameFull: 'Flap Tap (hubert@aetherbiomedical.com)',
    name: 'Flap Tap',
    email: 'hubert@aetherbiomedical.com'
  },
  {
    id: 6,
    nameFull: 'Rlap Tap (hubert@aetherbiomedical.com)',
    name: 'Rlap Tap',
    email: 'hubert@aetherbiomedical.com'
  }
];

const ROLES = [
  { id: 0, name: 'Clinician' },
  { id: 1, name: 'Patient' }
];

const medicalProfessionalsSchema = yup.object().shape({ clinicians: yup.array() });

const InviteMedicalProfessionalModal = ({ handleModalClose }) => {
  const { t } = useTranslation('medical_professionals');
  const defaultValues = {
    patient: null,
    clinicians: [{ name: null, id: null }],
    trainingConfirmation: false
  };

  const {
    control,
    handleSubmit: handleSubmitDevice,
    setValue,
    watch,
    trigger
  } = useForm<{
    clinicians: any;
  }>({
    defaultValues: defaultValues,
    // @ts-ignore
    resolver: yupResolver(medicalProfessionalsSchema),
    mode: 'onChange',
    shouldUnregister: false
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'clinicians'
  });
  const [clinicians] = watch(['clinicians']);

  const isLastFilled = () => {
    if (clinicians.length === 0) return true;

    return (
      clinicians?.[clinicians.length - 1]?.clinician && clinicians?.[clinicians.length - 1]?.role
    );
  };

  return (
    <Modal open={true} sx={{ width: '720px', ...ModalStyle }}>
      <FormContentWrapper
        title={t('medical_professionals:invite_modal.title', 'Invite new Medical Professional')}>
        <FormWrapper>
          <div style={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
            <FormComboBox
              label={t('medical_professionals:invite_modal.fields.patient', 'Patient')}
              id={'patient'}
              control={control}
              options={CLINICIANS}
              renderOption={(props, option) => clinicianOption(props, option, 'nameFull', 'name')}
              optionLabel={'nameFull'}
              nameProperty='name'
              placeholder={t(
                'medical_professionals:invite_modal.fields.patient_placeholder',
                'Search by name or e-mail'
              )}
            />
            <FormModalCheckboxesList>
              {fields.map((field, index) => {
                const isFirst = index === 0;

                return (
                  <div
                    style={{
                      display: 'grid',
                      gap: '8px',
                      gridTemplateColumns: '170px auto 40px',
                      alignItems: 'end'
                    }}
                    key={field.id}>
                    <FormComboBox
                      label={t('medical_professionals:invite_modal.fields.role', 'Role')}
                      id={`clinicians.${index}.role`}
                      control={control}
                      options={ROLES}
                      optionLabel='name'
                      showLabel={isFirst}
                    />
                    <FormComboBox
                      label={t('medical_professionals:invite_modal.fields.person', 'Person')}
                      id={`clinicians.${index}.clinician`}
                      control={control}
                      options={CLINICIANS}
                      renderOption={(props, option) =>
                        clinicianOption(props, option, 'nameFull', 'name')
                      }
                      optionLabel={'nameFull'}
                      nameProperty='name'
                      showLabel={isFirst}
                      placeholder={t(
                        'medical_professionals:invite_modal.fields.person_placeholder',
                        'Search by name or e-mail'
                      )}
                    />
                    <CustomButton
                      onClick={() => remove(index)}
                      type='button'
                      variant='text'
                      sx={{ minWidth: '40px', height: '40px' }}
                      Icon={CrossSvg}>
                      <></>
                    </CustomButton>
                  </div>
                );
              })}
            </FormModalCheckboxesList>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr auto 1fr',
              alignItems: 'center'
            }}>
            <div style={{ height: '1px', border: `1px solid ${myTheme.palette.primary}` }} />
            <CustomButton
              onClick={() => append({ role: null, clinician: null })}
              type='button'
              variant='text'
              iconProps={{ stroke: myTheme.palette.primary }}
              Icon={PlusSvg}
              disabled={!isLastFilled()}
              sx={{ margin: '0 4px' }}>
              {t('medical_professionals:invite_modal.buttons.add_next_person', 'Add next person')}
            </CustomButton>
            <div style={{ height: '1px', border: `1px solid ${myTheme.palette.primary}` }} />
          </div>
          <FormCheckbox
            label={t(
              'medical_professionals:invite_modal.fields.training_confirmation',
              'I confirm that the user I invited has been trained in the use of the application and is competent to do so.'
            )}
            id='trainingConfirmation'
            control={control}
          />
          <FormButtonsWrapper>
            <CustomButton
              data-testid='cancel-medical-form'
              type='button'
              color='light'
              onClick={handleModalClose}>
              {t('medical_professionals:invite_modal.buttons.cancel', 'Cancel')}
            </CustomButton>
            <LoadingButton data-testid='submit-patient-form' type='submit' loading={false}>
              <span>
                {t('medical_professionals:invite_modal.buttons.send_invitation', 'Send invitation')}
              </span>
            </LoadingButton>
          </FormButtonsWrapper>
        </FormWrapper>
      </FormContentWrapper>
    </Modal>
  );
};

export default InviteMedicalProfessionalModal;
