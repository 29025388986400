import useLocalSessionBackupConfig from 'configurator/hooks/bluetooth/useLocalSessionBackupConfig';
import React from 'react';
import toast from 'react-hot-toast';
import platform from 'platform';
import {
  getBootloaderStatusTimed,
  getEmergencyBatteryModeStatus,
  postActiveMode,
  postCommunicateMode
} from 'configurator/bluetooth-handler/bluetoothFunctions';
import { BootloaderStates } from 'bluetooth/Bluetooth/Defines';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import {
  ALLOWED_OS,
  FETCHING_STATES,
  MINIMAL_SUPPORTED_FIRMWARE_VERSION,
  REQUIRED_SUPPORTED_FIRMWARE_VERSION
} from 'configurator/consts/consts';
import { useDeviceUpdate, useUpdateDeviceConfig } from 'configurator/hooks/api/useDevice';
import { numericalFirmwareVersion } from 'configurator/utils/funcs';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useUiStore } from 'configurator/reducers/uiStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { MODALS } from 'configurator/views/Modals';
import { EMERGENCY_MODE_NOTIFICATION } from 'configurator/consts/notifications';
import { CompatibilitiesExtendOptions } from 'configurator/api/versions/versions.types';
import { getCompatibilities } from 'configurator/api/versions/versions';
import { BLOCK_MODALS } from 'configurator/consts/blockModals';
import { EVENTS } from 'configurator/consts/events';
import BluetoothWebController from 'configurator/bluetooth-handler/bluetoothWeb';
import { useTranslation } from 'react-i18next';

const bluetoothWeb = new BluetoothWebController();

const useBluetooth = () => {
  const { t } = useTranslation();
  const {
    bluetoothId,
    deviceId,
    firmware,
    amputeeId,
    getDeviceInfoAPI,
    setItemDeviceInfo,
    getFirmwareDevice,
    getPcbVersionDevice
  } = useDeviceInfoStore((state) => ({
    bluetoothId: state.bluetoothId,
    deviceId: Number(state.deviceId),
    firmware: state.firmware,
    amputeeId: state.amputeeId,
    setItemDeviceInfo: state.setItemDeviceInfo,
    getFirmwareDevice: state.getFirmwareDevice,
    getDeviceInfoAPI: state.getDeviceInfoAPI,
    getPcbVersionDevice: state.getPcbVersionDevice
  }));
  const {
    connectDevice,
    disconnectDevice,
    firstConnection,
    getInitialConfig,
    getInitialConfigAPI,
    slotSelected
  } = useConfigStore((state: any) => ({
    connectDevice: state.connectDevice,
    disconnectDevice: state.disconnectDevice,
    firstConnection: state.firstConnection,
    getInitialConfig: state.getInitialConfig,
    getInitialConfigAPI: state.getInitialConfigAPI,
    slotSelected: state.slotSelected
  }));
  const { mutateAsync: updateDevice } = useDeviceUpdate();
  const { registerDisconnectEvent, handleBackupConfig } = useLocalSessionBackupConfig();
  const { updateConfig } = useUpdateDeviceConfig();
  const { setItemUiStore, openModal, blockScreen, unblockScreen } = useUiStore((state) => ({
    setItemUiStore: state.setItemUiStore,
    openModal: state.openModal,
    blockScreen: state.blockScreen,
    unblockScreen: state.unblockScreen
  }));

  const synchronizeDeviceFirmware = async (firmwareVersionBluetooth: any) => {
    const deviceInfo = await getDeviceInfoAPI();
    const compatibilitiesParamsFirmware = {
      model: deviceInfo.model_id,
      software: process.env.REACT_APP_SOFTWARE_VERSION,
      pcb: deviceInfo.pcb_version.name,
      extend: [CompatibilitiesExtendOptions.features, CompatibilitiesExtendOptions.firmwareVersion]
    };
    const compatibilities = await getCompatibilities({ params: compatibilitiesParamsFirmware });
    const firmwareApiId = compatibilities?.items.find(
      (firmware: any) => firmware.firmware_version.name === firmwareVersionBluetooth
    )?.firmware_version_id;
    if (!firmwareApiId) {
      toast(
        t(
          'configurator:component.bluetooth.firmware_version_mismatch',
          'Firmware version of the device is different from the one stored in ADP, contact support.'
        ),
        { duration: 20000, icon: '⚠️' }
      );
      return;
    }
    await updateDevice({ deviceId, data: { firmware_version_id: firmwareApiId } });
    await getInitialConfigAPI();
  };

  const checkEmergencyMode = async () => {
    const emergencyStatus = await getEmergencyBatteryModeStatus();

    if (emergencyStatus?.[0] === 1) {
      toast(EMERGENCY_MODE_NOTIFICATION.message, EMERGENCY_MODE_NOTIFICATION.options);
      bluetoothDisconnect(true);
      return true;
    }

    return false;
  };

  const checkPlatform = () => {
    if (!platform?.os?.family) return false;

    if (!ALLOWED_OS.includes(platform.os.family)) {
      toast(
        <div>
          {t(
            'configurator:component.bluetooth.unsupported_os_message',
            'Your operating system is not supported in local mode, please read the '
          )}
          <a
            rel='noreferrer'
            target='_blank'
            href='https://7842652.fs1.hubspotusercontent-na1.net/hubfs/7842652/PDF%20Resources/Software%20Instruction%202022-web.pdf?__hstc=226640322.174d0b9ca68e2b3b395230cc62bcffb3.1672752111406.1672752111406.1672752111406.1&__hssc=226640322.3.1672752111406&__hsfp=2755598511&hsCtaTracking=b87f3133-a2a6-459a-9760-2f121df48152%7C4b51ac36-22a5-4b50-baf7-37d0de976790'>
            IFU
          </a>
        </div>,
        { duration: 30000, icon: '⚠️', id: 'unsupportedOs' }
      );
    }
    return true;
  };

  const bluetoothConnect = async (
    customBluetoothId: string | null = null,
    forceReconnect = false
  ) => {
    const isPlatformOk = checkPlatform();

    if (!isPlatformOk) return;

    let aborted = false;
    let connectedSuccessfully = false;
    const abort = () => {
      aborted = true;
    };

    try {
      window.addEventListener(EVENTS.disconnect, abort);
      registerDisconnectEvent();

      setItemUiStore('connectionState', FETCHING_STATES.loading);

      // Force device prompt to open
      if (forceReconnect) bluetoothWeb.forgetDevice();

      const connected = await connectDevice({ bluetoothId: customBluetoothId ?? bluetoothId });

      blockScreen(BLOCK_MODALS.CONNECT);

      if (!connected) {
        toast.error(
          t(
            'configurator:component.bluetooth.connection_failed',
            "Couldn't connect with device, try again"
          )
        );
        unblockScreen(BLOCK_MODALS.CONNECT);
        setItemUiStore('connectionState', FETCHING_STATES.failed);
        return;
      }

      if (aborted) return;

      await getPcbVersionDevice();

      if (aborted) return;

      const bootloaderStatus = await getBootloaderStatusTimed();
      // @ts-ignore
      if (bootloaderStatus[0] !== BootloaderStates.incative) {
        openModal(MODALS.bootloaderMode);
        unblockScreen(BLOCK_MODALS.CONNECT);
        setItemUiStore('connectionState', FETCHING_STATES.successful);
        return;
      }

      if (aborted) return;
      // @ts-ignore
      const firmwareDevice = await getFirmwareDevice();

      if (connected && firmwareDevice) {
        let config;
        const firmwareVersionBluetooth = firmwareDevice.firmwareVersion
          .slice(0, 8)
          .map((item: any) => String.fromCharCode(item))
          .join('');
        const firmwareVersionBluetoothParsed = `${firmwareVersionBluetooth[1]}.${firmwareVersionBluetooth[4]}.${firmwareVersionBluetooth[7]}`;
        const firmwareVersionAPI = firmware?.name;
        if (firmwareVersionAPI !== firmwareVersionBluetoothParsed) {
          await synchronizeDeviceFirmware(firmwareVersionBluetoothParsed);
        }

        if (aborted) return;

        const isInEmergency = await checkEmergencyMode();

        if (isInEmergency) throw new Error();

        if (
          numericalFirmwareVersion(firmwareVersionBluetoothParsed) >=
          MINIMAL_SUPPORTED_FIRMWARE_VERSION
        ) {
          if (aborted) return;
          config = await getInitialConfig();
          const modeSlot = slotSelected ?? config.modes[0].slot;

          if (aborted) return;
          await postCommunicateMode(modeSlot);
          await postActiveMode(modeSlot);
        }

        if (
          firstConnection &&
          numericalFirmwareVersion(firmwareVersionBluetoothParsed) >=
            MINIMAL_SUPPORTED_FIRMWARE_VERSION
        ) {
          unblockScreen(BLOCK_MODALS.CONNECT);
          const modesArray = config.modes.map((mode: any) => ({
            id: mode.id!,
            config: JSON.stringify(mode.config)
          }));
          await updateConfig({
            deviceId,
            data: {
              common: JSON.stringify(config.common),
              modes: modesArray
            }
          });
          await delay(500);
          await getInitialConfigAPI();
          unblockScreen(BLOCK_MODALS.CONNECT);
        }

        if (
          numericalFirmwareVersion(firmwareVersionBluetoothParsed) <
          REQUIRED_SUPPORTED_FIRMWARE_VERSION
        ) {
          setItemDeviceInfo('supported', false);
          openModal(MODALS.firmware);
        }
      }
      setItemUiStore('connectionState', FETCHING_STATES.successful);
      handleBackupConfig(deviceId);
      connectedSuccessfully = true;
    } catch (e) {
      console.log(e);
      unblockScreen(BLOCK_MODALS.CONNECT);
      setItemUiStore('connectionState', FETCHING_STATES.failed);
      connectedSuccessfully = false;
    }

    unblockScreen(BLOCK_MODALS.CONNECT);
    return connectedSuccessfully;
  };

  const bluetoothGetBasicInformation = async () => {
    const isPlatformOk = checkPlatform();

    if (!isPlatformOk) return false;

    try {
      setItemUiStore('connectionState', FETCHING_STATES.loading);

      bluetoothWeb.forgetDevice();
      const connected = await connectDevice({ bluetoothId: null });

      blockScreen(BLOCK_MODALS.CONNECT_REGISTER);

      if (!connected) {
        toast.error(
          t(
            'configurator:component.bluetooth.connection_failed',
            "Couldn't connect with device, try again"
          )
        );
        unblockScreen(BLOCK_MODALS.CONNECT_REGISTER);
        setItemUiStore('connectionState', FETCHING_STATES.failed);
        return false;
      }

      const deviceData = await getPcbVersionDevice();
      setItemUiStore('connectionState', FETCHING_STATES.successful);
      return deviceData;
    } catch (e) {
      console.log(e);
      setItemUiStore('connectionState', FETCHING_STATES.failed);
      return false;
    } finally {
      unblockScreen(BLOCK_MODALS.CONNECT_REGISTER);
    }
  };

  const bluetoothDisconnect = async (showServicing = true) => {
    await disconnectDevice();
    if (amputeeId && showServicing) openModal(MODALS.servicingModal);
  };

  return { bluetoothConnect, bluetoothDisconnect, bluetoothGetBasicInformation };
};

export default useBluetooth;
