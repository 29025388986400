import React, { useEffect, useMemo, useState } from 'react';
import { initialState } from 'configurator/reducers/helpers/initialState';
import {
  aetherBatteryVoltageFunctions,
  genericSwitchingMap,
  genericSwitchingReversedMap
} from 'configurator/utils/definesLocal';
import { useTranslation } from 'react-i18next';
import { OptionsWithSlider } from '../../../components/molecules/Sliders/Sliders';

const sliderLimits = {
  min: aetherBatteryVoltageFunctions.percentToVoltage(5),
  max: aetherBatteryVoltageFunctions.percentToVoltage(30)
};

const BatteryBeep = ({
  addHistoryProsthesis,
  batteryBeep,
  batteryBeepPermission,
  replayIsEnabled,
  setConfigProperty
}: any) => {
  const { t } = useTranslation();
  const [batteryBeepSliderLocal, setBatteryBeepSliderLocal] = useState<any>(
    // @ts-ignore
    initialState.config.batteryBeep![0]
  );

  const handleBatteryBeepSlider = (value: any) => {
    addHistoryProsthesis(setConfigProperty('batteryBeep', [value, batteryBeep[1]]));
  };

  const handleBatteryBeepStatus = (value: any) => {
    addHistoryProsthesis(setConfigProperty('batteryBeep', [batteryBeep[0], value]));
  };

  useEffect(() => {
    if (batteryBeep) {
      setBatteryBeepSliderLocal(batteryBeep?.[0]);
    }
  }, [batteryBeep]);

  const BatteryBeep = useMemo(
    () => (
      <OptionsWithSlider
        header={t('configurator:component.battery_beep.header', 'Low battery beep')}
        toggleOptions={[
          'configurator:constants.generic_switching.on',
          'configurator:constants.generic_switching.off'
        ]}
        disabled={replayIsEnabled || !batteryBeepPermission}
        disableSlider={Boolean(!batteryBeep[1])}
        toggleValue={genericSwitchingMap.get(batteryBeep[1])}
        sliderValue={batteryBeepSliderLocal}
        handleSliderChange={(e: any, value: any) => setBatteryBeepSliderLocal(value)}
        sliderLimits={sliderLimits}
        sliderLabel={t(
          'configurator:component.config_template.battery_beep.slider_label',
          'Level:'
        )}
        handleToggleChange={(value: any) => {
          handleBatteryBeepStatus(genericSwitchingReversedMap.get(value));
        }}
        handleSliderOnAfterChange={(...args: any) => handleBatteryBeepSlider(args[1])}
        description={t(
          'configurator:component.battery_beep.description',
          'Sets low battery checker alarm voltage level and state of activation.'
        )}
        tooltip={t('configurator:component.battery_beep.tooltip', 'Hello')}
        valueToDisplay={`${aetherBatteryVoltageFunctions.voltageToPercent(
          batteryBeepSliderLocal
        )}%`}
      />
    ),
    [batteryBeep, batteryBeepSliderLocal, batteryBeepPermission]
  );

  return BatteryBeep;
};

export default BatteryBeep;
