/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import styled from 'styled-components';
import { JaaSMeeting } from '@jitsi/react-sdk';
import StandaloneChart from 'configurator/views/Graph/StandaloneChart';
import { useMeetingStore } from 'configurator/reducers/meetingStore';
import useMeeting from 'configurator/hooks/useMeeting';
import { ViewModes } from 'configurator/utils/ViewModes/viewModes';
import { Rnd } from 'react-rnd';
import Card from 'adp-panel/components/Card/Card';
import ClearIcon from '@mui/icons-material/Clear';
import CustomHeaderSmall from 'configurator/components/atoms/Typography/CustomHeaderSmall';

const OuterChartWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const ResizeParent = ({ children, onStartDrag, onStopDrag, closeWindow }) => (
  <Rnd
    default={{
      x: 0,
      y: 0,
      width: '30vw',
      height: '70vh'
    }}
    bounds={'parent'}
    style={{ zIndex: '999' }}
    onDragStart={onStartDrag}
    dragHandleClassName='drag-handle'
    onDragStop={onStopDrag}>
    <Card style={{ height: '100%', paddingBottom: '120px' }}>
      <div
        className='drag-handle'
        style={{ height: '30px', background: 'lightgrey', cursor: 'move' }}>
        Drag here (PLACEHOLDER)
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <CustomHeaderSmall header='EMG settings' sx={{ margin: 0 }} />
        </div>
        <div onClick={closeWindow} style={{ cursor: 'pointer' }}>
          <ClearIcon />
        </div>
      </div>
      {children}
    </Card>
  </Rnd>
);

const Meeting = () => {
  const [isDragging, setIsDragging] = useState(false);
  const { meetingStatus, viewMode, meetingJwt, roomId, setItemMeeting } = useMeetingStore();
  const { handleCloseMeeting } = useMeeting();

  const onStartDrag = () => setIsDragging(true);
  const onStopDrag = () => setIsDragging(false);

  const isEmgOn = viewMode === ViewModes.standardEMG || viewMode === ViewModes.minimalEMG;

  const closeWindow = () => {
    setItemMeeting('viewMode', ViewModes.standard);
  };

  return meetingStatus ? (
    <ResizeParent onStartDrag={onStartDrag} onStopDrag={onStopDrag} closeWindow={closeWindow}>
      <OuterChartWrapper>
        {roomId && (
          <div style={{ flex: '1', zIndex: '0', pointerEvents: isDragging ? 'none' : 'auto' }}>
            <JaaSMeeting
              appId={process.env.REACT_APP_JITSI_APP_ID!}
              roomName={roomId}
              getIFrameRef={(iframeRef) => {
                iframeRef.style.width = '100%';
                iframeRef.style.height = '100%';
              }}
              jwt={meetingJwt!}
              onReadyToClose={handleCloseMeeting}
            />
          </div>
        )}
        {isEmgOn && (
          <div style={{ flex: 1, height: '50%' }}>
            <StandaloneChart style={{ height: '100%' }} />
          </div>
        )}
      </OuterChartWrapper>
      {!meetingStatus && isEmgOn && (
        <OuterChartWrapper>
          <StandaloneChart style={{ height: '100%' }} />
        </OuterChartWrapper>
      )}
    </ResizeParent>
  ) : (
    <ResizeParent onStartDrag={onStartDrag} onStopDrag={onStopDrag} closeWindow={closeWindow}>
      {isEmgOn && <StandaloneChart style={{ height: '100%' }} />}
    </ResizeParent>
  );
};

export default Meeting;
