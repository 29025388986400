import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useModal } from '../../hooks/api/useModal';
import { useQueryClient } from '@tanstack/react-query';
import { USERS_QUERY_KEY, useUserDelete } from '../../hooks/api/useUsers';
import { userHasPermissions } from '../../utils/permissionUtils';
import {
  RoleEnum,
  UserEntry,
  UserExtendOptions,
  UsersQueryParams
} from '../../api/users/users.types';
import useUserData from '../../../hooks/useUserData';
import { useUsers } from '../../hooks/api/useUsers';
import { PATIENTS_PERMISSIONS } from '../../constants/rolesPermissions';
import Card from 'adp-panel/components/Card/Card';
import { Chip, CircularProgress, Divider, Typography } from '@mui/material';
import { ReactComponent as DeviceSvg } from 'assets/device.svg';
import { ReactComponent as AddUserSvg } from 'assets/add_user.svg';
import { ReactComponent as NoUsersSvg } from 'assets/no_users.svg';
import { ReactComponent as ListSvg } from 'assets/list.svg';
import { ReactComponent as GridSvg } from 'assets/grid.svg';
import { ReactComponent as CogSvg } from 'assets/cog_configure.svg';
import { ReactComponent as ComputerSvg } from 'assets/computer.svg';
import { ReactComponent as TrashSvg } from 'assets/trash-icon.svg';
import DotsBackground from 'assets/dots.svg';
import CustomButton from 'components/Button/CustomButton';
import PatientCard from '../../components/PatientCard/PatientCard';
import { useNavigate } from 'react-router-dom';
import * as routes from 'constants/routes';
import CustomTable, { TableHeader } from 'adp-panel/components/Table/CustomTable';
import UserListAvatar from 'components/UserListAvatar/UserListAvatar';
import LoaderWrapper from 'components/Loader/Loader';
import { TableLink } from 'adp-panel/components/Links/Links';
import { FETCHING_STATES } from 'configurator/consts/consts';
import { useSessionStart } from 'hooks/useSessionStart';

const mapData = (patients?: UserEntry[]) => {
  if (!patients) {
    return [];
  }
  return patients.map((item: UserEntry) => {
    return {
      id: item.id,
      name: item.name,
      email: item.email,
      mrn: item.mrn,
      company: item?.location?.company?.name,
      companyId: item?.location?.company?.id,
      location: item?.location?.name,
      locationId: item?.location?.id,
      role: item.roles?.join(','),
      country: item.location?.company?.country?.name,
      devices: item.devices,
      clinician: item?.clinician
    };
  });
};

export const hasPermissionToDeletePatient = (
  clinicianId: any,
  rolesByName: any,
  userData: any
): boolean => {
  if (
    userHasPermissions([RoleEnum.clinician], rolesByName) &&
    userHasPermissions(PATIENTS_PERMISSIONS.DELETE, rolesByName)
  ) {
    return clinicianId === userData.id;
  }

  return userHasPermissions(PATIENTS_PERMISSIONS.DELETE, rolesByName);
};

const mapPatientsRows = (apiData: UserEntry[]) =>
  apiData.map((patient) => ({
    id: patient.id,
    name: patient.name,
    devices: patient.devices
  }));

const PatientsDashboard = () => {
  const { t } = useTranslation('patients');
  const { data: userData, rolesByName } = useUserData();
  const [selectedPatient, setSelectedPatient] = useState<UserEntry>();
  const queryClient = useQueryClient();
  const queryParams: UsersQueryParams = {
    extend: [
      UserExtendOptions.roles,
      UserExtendOptions.devices,
      UserExtendOptions.clinician,
      UserExtendOptions.devicesAsClinician
    ],
    roles: 'Amputee'
  };

  const {
    result: patientsData,
    total: totalPatients,
    isLoading: isLoadingPatients
  } = useUsers(queryParams);
  const patientsExist = patientsData?.length > 0;

  const [tableView, setTableView] = useState(false);
  const navigate = useNavigate();

  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const { handleOpenRemoteSession, handleOpenLocalSession, sessionStartingState } =
    useSessionStart();

  const { mutateAsync: deleteUser, isLoading: isLoadingDelete } = useUserDelete();

  const handleDeleteModal = (patient: UserEntry) => {
    setSelectedPatient(patient);
    handleModalOpen();
  };

  const handleDelete = async () => {
    if (selectedPatient) {
      await deleteUser(selectedPatient.id);
      handleModalClose();
      await queryClient.invalidateQueries([USERS_QUERY_KEY]);
    }
  };

  const redirectCreatePatient = () => {
    navigate(routes.PATIENT_ADD);
  };

  const PatientsDashboardActions = (
    <div style={{ display: 'flex', gap: '16px' }}>
      <CustomButton Icon={AddUserSvg} color='lightBlue' onClick={redirectCreatePatient}>
        {t('patients:dashboard.create_patient', 'Create new patient')}
      </CustomButton>
      <Divider orientation='vertical' flexItem />
      <CustomButton
        Icon={tableView ? GridSvg : ListSvg}
        color='light'
        onClick={() => setTableView((prev) => !prev)}
      />
    </div>
  );

  const NoPatientsDashboard = (
    <Card style={{ display: 'flex', justifyContent: 'center', padding: '0' }}>
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '20px',
          padding: '40px',
          backgroundImage: `linear-gradient(to bottom, rgba(255,255, 255, 0.5), rgba(255, 255, 255, 1)), url(${DotsBackground})`
        }}>
        <Card style={{ display: 'flex', alignItems: 'center' }}>
          <NoUsersSvg />
        </Card>
        <Typography sx={{ fontWeight: 600 }}>
          {t('patients:dashboard.no_patients', 'No patients found')}
        </Typography>
        <Typography variant='caption' sx={{ width: '250px' }}>
          {t(
            'patients:dashboard.no_patients_description',
            'Click the button below to add your first patient or connect to Zeus.'
          )}
        </Typography>
        <div style={{ display: 'flex', gap: '16px' }}>
          <CustomButton Icon={AddUserSvg} color='lightBlue' onClick={redirectCreatePatient}>
            {t('patients:dashboard.create_patient', 'Create new patient')}
          </CustomButton>
          <CustomButton Icon={DeviceSvg} iconProps={{ fill: 'white' }}>
            {t('patients:dashboard.connect_zeus', 'Connect to Zeus')}
          </CustomButton>
        </div>
      </div>
    </Card>
  );

  const PatientsWithCards = (
    <Card>
      <TableHeader
        tableHeader={t('patients:dashboard.table.header', 'My patients')}
        actions={PatientsDashboardActions}
      />
      <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
        {patientsData?.map((patient) => (
          <PatientCard
            name={patient.user_name}
            key={patient.id}
            serial={patient?.devices[0]?.serial}
            loadingSession={sessionStartingState === FETCHING_STATES.loading}
            handleConfigure={() =>
              handleOpenLocalSession(
                patient?.devices[0]?.serial,
                patient?.devices[0]?.bluetooth_id,
                patient?.devices[0]?.id
              )
            }
            handleProfile={() => navigate(`${routes.PATIENTS}/${patient.id}`)}
            handleSession={() => handleOpenRemoteSession(patient.id)}
          />
        ))}
      </div>
    </Card>
  );

  const PatientsTable = (
    <Card>
      <CustomTable
        actions={PatientsDashboardActions}
        tableHeader={t('patients:dashboard.table.header', 'My patients')}
        tableData={patientsData}
        totalItems={totalPatients}
        isLoading={isLoadingPatients}
        columns={[
          {
            field: 'name',
            headerName: t('patients:dashboard.table.name_columns', 'Name'),
            flex: 1,
            renderCell: (params) => {
              const { name, id } = params.row;
              return (
                <UserListAvatar name={name}>
                  <TableLink to={`${routes.PATIENTS}/${id}`}>{name}</TableLink>
                </UserListAvatar>
              );
            }
          },
          {
            field: 'devices',
            headerName: t('patients:dashboard.table.device_column', 'Devices'),
            flex: 1,
            renderCell: (params) => {
              const { devices } = params.row;
              return (
                <div>
                  {devices.map((device) => (
                    <Chip size='small' label={device.serial} sx={{ marginRight: '4px' }} />
                  ))}
                </div>
              );
            }
          },
          {
            field: 'actions',
            type: 'actions',
            flex: 1,
            headerName: t('patients:dashboard.table.actions_columns', 'Actions'),
            getActions: (params) => {
              const { id, devices } = params.row;
              return [
                <CustomButton
                  Icon={CogSvg}
                  disabled={!devices?.[0]?.serial}
                  onClick={() =>
                    handleOpenLocalSession(
                      devices[0]?.serial,
                      devices[0]?.bluetooth_id,
                      devices[0]?.id
                    )
                  }>
                  {t('patients:dashboard.table.button.configure_zeus', 'Configure Zeus')}
                </CustomButton>,
                <div style={{ display: 'flex', gap: '16px' }}>
                  <CustomButton
                    Icon={ComputerSvg}
                    color='light'
                    disabled={!devices?.[0]?.serial}
                    onClick={() => handleOpenRemoteSession(id)}>
                    {t(
                      'patients:dashboard.table.button.start_remote_session',
                      'Start Remote Session'
                    )}
                  </CustomButton>
                  <Divider orientation='vertical' flexItem />
                  <CustomButton Icon={TrashSvg} color='light' onClick={() => false} />
                </div>
              ];
            }
          }
        ]}
        mapTableData={mapPatientsRows}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </Card>
  );

  const showPatientsDashboard = () => {
    if (isLoadingPatients)
      return (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      );

    if (patientsExist) {
      if (tableView) return PatientsTable;

      return PatientsWithCards;
    }

    return NoPatientsDashboard;
  };

  return <>{showPatientsDashboard()}</>;
};

export default PatientsDashboard;
