import styled from 'styled-components';
import { HeaderM, HeaderXL } from 'configurator/components/atoms/Typography/Typography';
import { DisableFunctionality } from 'configurator/utils/disableFunctionalityModifiier';

export const Header1 = styled.h2`
  ${HeaderXL};
  color: ${({ theme }) => theme.colorPrimary};
  margin-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 40px;
  }
`;

export const Header2 = styled.h3`
  ${HeaderM};
  color: ${({ theme }) => theme.typography.colors.secondary};
`;

export const SettingsContainer = styled.div`
  display: grid;
  max-width: 800px;
  gap: 32px;
`;

export const ConfigurationSection = styled.div<{ disabled?: any }>`
  ${({ disabled }) => (disabled ? DisableFunctionality : null)}
`;
