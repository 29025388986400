import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, DialogContent } from '@mui/material';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import useBluetooth from 'configurator/hooks/bluetooth/useConnect';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import useUnsaved from 'configurator/hooks/useUnsaved';
import ModalBase from './ModalBase';
import { pauseSoundElement, playSoundElement } from 'App';
import { useUiStore } from 'configurator/reducers/uiStore';
import { FETCHING_STATES } from 'configurator/consts/consts';

const ParagraphBold = styled.p`
  ${TextS};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
`;

type ModalProps = {
  handleClose: Function;
  handleAccept: Function;
};

const DisruptiveDisconnectModal = ({ handleClose, handleAccept }: ModalProps) => {
  const { isUnsaved } = useUnsaved();
  const { t } = useTranslation();
  const { bluetoothConnect } = useBluetooth();
  const { deviceConnected, amputeeId } = useDeviceInfoStore((state: any) => ({
    deviceConnected: state.connected,
    amputeeId: state.amputeeId
  }));
  const getInitialConfigAPI = useConfigStore((state: any) => state.getInitialConfigAPI);
  const { initialConfigState } = useUiStore((state) => ({
    initialConfigState: state.initialConfigState
  }));

  useEffect(() => {
    playSoundElement();
  }, []);

  const accepted = async () => {
    pauseSoundElement();
    handleAccept();
    handleClose();
  };

  const denied = async () => {
    pauseSoundElement();
    await getInitialConfigAPI();
    handleClose();
  };

  const reconnect = () => {
    pauseSoundElement();
    bluetoothConnect();
    handleClose();
  };

  const canSaveSettings =
    amputeeId && isUnsaved && initialConfigState === FETCHING_STATES.successful;

  return (
    <ModalBase
      handleClick={() => {
        pauseSoundElement();
        handleClose();
      }}
      header={t(
        'configurator:component.disruptive_disconnect_modal.device_was_disconnected_unexpectedly',
        'Device was disconnected unexpectedly'
      )}
      allowBackDropClose={false}>
      <DialogContent>
        <ParagraphBold>
          {canSaveSettings
            ? t(
                'configurator:component.disruptive_disconnect_modal.changes_not_saved_do_you_want_to_save_them',
                'Changes were not saved, do you want to save them?'
              )
            : t(
                'configurator:component.disruptive_disconnect_modal.device_disconnected_do_you_want_to_reconnect',
                'Device has been disconnected, do you want to reconnect?'
              )}
        </ParagraphBold>
        <ButtonsWrapper>
          {canSaveSettings && (
            <Button
              sx={{ width: 200 }}
              onClick={denied}
              variant={amputeeId ? 'outlined' : 'contained'}>
              {t(
                'configurator:component.disruptive_disconnect_modal.no_dont_save',
                "No, don't save"
              )}
            </Button>
          )}
          {!deviceConnected && (
            <Button sx={{ width: 200 }} onClick={reconnect} variant='outlined'>
              {t('configurator:component.disruptive_disconnect_modal.reconnect', 'Reconnect')}
            </Button>
          )}
          {canSaveSettings && (
            <Button sx={{ width: 200 }} onClick={accepted}>
              {t('configurator:component.disruptive_disconnect_modal.yes_save', 'Yes, save')}
            </Button>
          )}
        </ButtonsWrapper>
      </DialogContent>
    </ModalBase>
  );
};

export default DisruptiveDisconnectModal;
