const commonPositionsDropdowns = {
  yPositionCommon1: '46%',
  yPositionCommon2: '88%',
  xPositionCommon1: '11%',
  xPositionCommon2: '30%'
};

export const positionsDropdowns = [
  { top: '14%', left: '50%', transform: 'translateX(-50%) translateY(-50%)' },
  {
    top: commonPositionsDropdowns.yPositionCommon1,
    right: commonPositionsDropdowns.xPositionCommon1,
    transform: 'translateX(50%) translateY(-50%)'
  },
  {
    top: commonPositionsDropdowns.yPositionCommon2,
    right: commonPositionsDropdowns.xPositionCommon2,
    transform: 'translateX(50%) translateY(-50%)'
  },
  {
    top: commonPositionsDropdowns.yPositionCommon2,
    left: commonPositionsDropdowns.xPositionCommon2,
    transform: 'translateX(-50%) translateY(-50%)'
  },
  {
    top: commonPositionsDropdowns.yPositionCommon1,
    left: commonPositionsDropdowns.xPositionCommon1,
    transform: 'translateX(-50%) translateY(-50%)'
  }
];

export const arrowWidth = '20%';
const baseDeg = 34;
const baseDegBottom = 122;

const commonPositionsPrimary = {
  yPositionCommon1: '21%',
  yPositionCommon2: '72%',
  xPositionCommon1: '25%',
  xPositionCommon2: '15%'
};

export const arrowsPositionsPrimary = [
  {
    right: commonPositionsPrimary.xPositionCommon1,
    top: commonPositionsPrimary.yPositionCommon1,
    transform: `translateX(50%) translateY(-50%) rotate(${baseDeg}deg)`
  },
  {
    right: commonPositionsPrimary.xPositionCommon2,
    top: commonPositionsPrimary.yPositionCommon2,
    transform: `translateX(50%) translateY(-50%) rotate(${baseDegBottom}deg)`
  },
  {
    left: '50%',
    top: '88%',
    transform: `translateX(-50%) translateY(-50%) rotate(${180}deg)`
  },
  {
    left: commonPositionsPrimary.xPositionCommon2,
    top: commonPositionsPrimary.yPositionCommon2,
    transform: `translateX(-50%) translateY(-50%) rotate(-${baseDegBottom}deg)`
  },
  {
    left: commonPositionsPrimary.xPositionCommon1,
    top: commonPositionsPrimary.yPositionCommon1,
    transform: `translateX(-50%) translateY(-50%) rotate(-${baseDeg}deg)`
  }
];

const commonPositionsSecondary = {
  yPositionCommon1: '23%',
  yPositionCommon2: '71%',
  xPositionCommon1: '18%',
  xPositionCommon2: '27%'
};

export const arrowsPositionsSecondary = [
  {
    right: commonPositionsSecondary.xPositionCommon2,
    top: commonPositionsSecondary.yPositionCommon1,
    transform: `translateX(50%) translateY(-50%) rotate(${baseDeg}deg)`
  },
  {
    right: commonPositionsSecondary.xPositionCommon1,
    top: commonPositionsSecondary.yPositionCommon2,
    transform: `translateX(50%) translateY(-50%) rotate(${baseDegBottom}deg)`
  },
  {
    left: '50%',
    top: '85%',
    transform: `translateX(-50%) translateY(-50%) rotate(${180}deg)`
  },
  {
    left: commonPositionsSecondary.xPositionCommon1,
    top: commonPositionsSecondary.yPositionCommon2,
    transform: `translateX(-50%) translateY(-50%) rotate(-${baseDegBottom}deg)`
  },
  {
    left: commonPositionsSecondary.xPositionCommon2,
    top: commonPositionsSecondary.yPositionCommon1,
    transform: `translateX(-50%) translateY(-50%) rotate(-${baseDeg}deg)`
  }
];

const commonPositionsPrimarySwitch = {
  yPositionCommon1: '17%',
  yPositionCommon2: '74%',
  xPositionCommon1: '5%',
  xPositionCommon2: '15%'
};

export const gripSwitchIndicatorPrimary = [
  {
    right: commonPositionsPrimarySwitch.xPositionCommon2,
    top: commonPositionsPrimarySwitch.yPositionCommon1,
    transform: `translateX(50%) translateY(-50%)`
  },
  {
    right: commonPositionsPrimarySwitch.xPositionCommon1,
    top: commonPositionsPrimarySwitch.yPositionCommon2,
    transform: `translateX(50%) translateY(-50%)`
  },
  {
    left: '50%',
    top: '92%',
    transform: `translateX(-50%) translateY(-50%)`
  },
  {
    left: commonPositionsPrimarySwitch.xPositionCommon1,
    top: commonPositionsPrimarySwitch.yPositionCommon2,
    transform: `translateX(-50%) translateY(-50%)`
  },
  {
    left: commonPositionsPrimarySwitch.xPositionCommon2,
    top: commonPositionsPrimarySwitch.yPositionCommon1,
    transform: `translateX(-50%) translateY(-50%)`
  }
];

const commonPositionsSecondarySwitch = {
  yPositionCommon1: '30%',
  yPositionCommon2: '67%',
  xPositionCommon1: '28%',
  xPositionCommon2: '30%'
};

export const gripSwitchIndicatorSecondary = [
  {
    right: commonPositionsSecondarySwitch.xPositionCommon2,
    top: commonPositionsSecondarySwitch.yPositionCommon1,
    transform: `translateX(50%) translateY(-50%)`
  },
  {
    right: commonPositionsSecondarySwitch.xPositionCommon1,
    top: commonPositionsSecondarySwitch.yPositionCommon2,
    transform: `translateX(50%) translateY(-50%)`
  },
  {
    left: '50%',
    top: '80%',
    transform: `translateX(-50%) translateY(-50%)`
  },
  {
    left: commonPositionsSecondarySwitch.xPositionCommon1,
    top: commonPositionsSecondarySwitch.yPositionCommon2,
    transform: `translateX(-50%) translateY(-50%)`
  },
  {
    left: commonPositionsSecondarySwitch.xPositionCommon2,
    top: commonPositionsSecondarySwitch.yPositionCommon1,
    transform: `translateX(-50%) translateY(-50%)`
  }
];
