import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import ConfirmationLoader from '../../layouts/ConfirmationLoader';

const InnerWrapper = styled.div`
  width: 550px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10px;
`;

const TextWrapper = styled.div`
  margin-bottom: 30px;
`;

const LogoutInProgress = styled.div`
  margin-bottom: 30px;
  margin-top: 30px;
  text-align: center;
`;

type AutoLogoutModalProps = {
  resetAction: () => void;
  logoutAction: () => void;
  isLoading: boolean;
  remainingTimeInSeconds: number;
};

let remainingTimeInterval: ReturnType<typeof setInterval> | null = null;

const AutoLogoutModal = ({
  resetAction,
  logoutAction,
  isLoading,
  remainingTimeInSeconds = 10
}: AutoLogoutModalProps) => {
  const { t } = useTranslation();
  const [autoLogoutRemainingTime, setAutoLogoutRemainingTime] = useState(remainingTimeInSeconds);

  useEffect(() => {
    if (autoLogoutRemainingTime <= 0) {
      if (remainingTimeInterval) clearInterval(remainingTimeInterval);
      logoutAction();
    }
  }, [autoLogoutRemainingTime]);

  useEffect(() => {
    remainingTimeInterval = setInterval(() => {
      setAutoLogoutRemainingTime((prev) => prev - 1);
    }, 1000);

    return () => {
      if (remainingTimeInterval) clearInterval(remainingTimeInterval);
    };
  }, []);

  return (
    <Dialog title={t('common:auto_logout.dialog_title', 'Are you there?')} onClose={resetAction}>
      <InnerWrapper>
        {isLoading ? (
          <>
            <ConfirmationLoader inline={true} />
            <LogoutInProgress>
              {t('common:auto_logout.logout_in_progress', 'Logout in progress...')}
            </LogoutInProgress>
          </>
        ) : (
          <>
            <TextWrapper>
              {t(
                'common:auto_logout.still_active_message',
                'Are you still active? We will log you out in {{autoLogoutRemainingTime}} seconds due to inactivity.',
                { autoLogoutRemainingTime }
              )}
            </TextWrapper>
            <ButtonsWrapper>
              <Button type='button' onClick={resetAction} themeColor={'primary'}>
                {t('common:auto_logout.button_yes', "Yes, I'm here")}
              </Button>
              <Button type='button' onClick={logoutAction} themeColor={'error'}>
                {t('common:auto_logout.button_logout', 'Log me out')}
              </Button>
            </ButtonsWrapper>
          </>
        )}
      </InnerWrapper>
    </Dialog>
  );
};

export default AutoLogoutModal;
