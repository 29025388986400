import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useRedirectStore } from 'reducers/redirectReducer';
import useUserData from '../../../../hooks/useUserData';
import {
  SupportTicketExtendOptions,
  SupportTicketsQueryParams
} from '../../../api/tickets/tickets.types';
import MessageModal from '../../../components/Modals/MessageModal';
import TicketModal from '../../../components/Modals/TicketModal';
import DotsBackground from 'assets/dots.svg';
import { ReactComponent as NoUsersSvg } from 'assets/no_users.svg';
import {
  MESSAGES_QUERY_KEY,
  useMessageMarkArchive,
  useMessageMarkRead,
  useMessagesInfinite
} from '../../../hooks/api/useMessages';
import { useModal } from '../../../hooks/api/useModal';
import {
  SUPPORT_TICKETS_QUERY_KEY,
  useSupportTickets,
  useTicketClose,
  useTicketReOpen
} from '../../../hooks/api/useTickets';
import CustomTable, { TableHeader } from 'adp-panel/components/Table/CustomTable';
import {
  SupportTicketEntry,
  SupportTicketMessageEntry,
  SupportTicketAttachmentEntry
} from '../../../api/tickets/tickets.types';
import Card from 'adp-panel/components/Card/Card';
import { CircularProgress, Link, Typography } from '@mui/material';
import { ReactComponent as MessagesSvg } from 'assets/messages.svg';
import LoaderWrapper from 'components/Loader/Loader';

dayjs.extend(utc);
dayjs.extend(timezone);

const checkIsRecordProblemTicket = (ticket: SupportTicketEntry) => {
  if (ticket?.messages?.length === 0) return false;
  return !!Array.from(ticket.messages).find((message: SupportTicketMessageEntry) => {
    if (message.attachments === undefined) {
      return;
    }
    if (message.attachments.length === 0) {
      return;
    }

    return Array.from(message.attachments).find(
      (attachment: SupportTicketAttachmentEntry) => attachment.type === 'application/json'
    );
  });
};

export const transformTicketFromAPI = (ticket: SupportTicketEntry) => {
  const formatDate = (date: string) => dayjs(date).tz(dayjs.tz.guess()).format('MM/DD/YYYY, HH:mm');
  const formatDateDate = (date: string) => dayjs(date).tz(dayjs.tz.guess()).format('MM/DD/YYYY');
  const formatDateHour = (date: string) => dayjs(date).tz(dayjs.tz.guess()).format('HH:mm');

  const formatTitle = (title: string) =>
    title?.length > 250 ? title.substring(0, 250) + '...' : title;

  const formatMessage = (message: SupportTicketMessageEntry) => ({
    ...message,
    created_at: formatDate(message.created_at),
    created_at_date: formatDateDate(message.created_at),
    created_at_hour: formatDateHour(message.created_at)
  });

  const transformTicket = (ticket: SupportTicketEntry) => {
    const titleFromMessageContent = ticket?.messages[0]?.content
      ? formatTitle(ticket.messages[0].content)
      : '';
    const ticketTitle =
      ticket?.messages[0]?.title && ticket.messages[0].title.length > 0
        ? formatTitle(ticket.messages[0].title)
        : titleFromMessageContent;

    const lastMessageDate =
      ticket.messages.length > 0
        ? formatDate(ticket.messages[ticket.messages.length - 1].created_at)
        : null;

    return {
      id: ticket.id,
      sender: ticket.sender.name,
      recipient: ticket.recipient.name,
      date: formatDate(ticket.created_at),
      lastMessageDate: lastMessageDate,
      isRecordReplay: checkIsRecordProblemTicket(ticket),
      title: ticketTitle,
      messages: ticket?.messages?.map(formatMessage) ?? []
    };
  };

  return transformTicket(ticket);
};

const mapTicketData = (apiData: SupportTicketEntry[]) => {
  const transformFunction = (data: SupportTicketEntry[]) => data?.map(transformTicketFromAPI) ?? [];

  return transformFunction(apiData);
};

const NoMessages = () => (
  <>
    <TableHeader tableHeader='Inbox' />
    <div style={{ display: 'flex', justifyContent: 'center', padding: '0' }}>
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '20px',
          padding: '40px 180px',
          backgroundImage: `linear-gradient(to bottom, rgba(255,255, 255, 0.5), rgba(255, 255, 255, 1)), url(${DotsBackground})`
        }}>
        <Card style={{ display: 'flex', alignItems: 'center' }}>
          <MessagesSvg />
        </Card>
        <Typography sx={{ fontWeight: 600 }}>
          <Translation ns='dashboard'>
            {(t) => {
              return t('dashboard:inbox.grid.no_messages', 'No messages');
            }}
          </Translation>
        </Typography>
      </div>
    </div>
  </>
);

export const InboxGrid = () => {
  const { t } = useTranslation('dashboard');
  const [selectedMessage, setSelectedMessage] = useState<any>();
  const [selectedTicket, setSelectedTicket] = useState<number | undefined>();
  const {
    isOpen: isMessageModalOpen,
    handleOpen: handleMessageModalOpen,
    handleClose: handleMessageModalClose
  } = useModal();
  const { isOpen: isModalOpen, handleOpen, handleClose } = useModal();
  const { mutateAsync: markMessageArchive } = useMessageMarkArchive();
  const { mutateAsync: markMessageRead } = useMessageMarkRead();
  const { mutateAsync: closeTicket } = useTicketClose();
  const { mutateAsync: reopenTicket } = useTicketReOpen();
  const queryClient = useQueryClient();
  const { setTicketRedirectId, ticketId } = useRedirectStore((state) => ({
    setTicketRedirectId: state.setTicketRedirectId,
    ticketId: state.ticketId
  }));
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });

  const handleShowTicket = (ticketId: number) => {
    setSelectedTicket(ticketId);
    handleOpen();
  };

  const handleCloseTicket = async (ticketId: number) => {
    await closeTicket(ticketId);
    await queryClient.invalidateQueries([SUPPORT_TICKETS_QUERY_KEY]);
  };

  const handleReopenTicket = async (ticketId: number) => {
    await reopenTicket({ ticketId });
    await queryClient.invalidateQueries([SUPPORT_TICKETS_QUERY_KEY]);
  };

  const handleShowMessage = (dataItem: any) => {
    setSelectedMessage(dataItem);
    handleMessageModalOpen();
  };

  const handleArchiveMessage = async (messageId: number) => {
    await markMessageArchive({ messageId });
    await queryClient.invalidateQueries([MESSAGES_QUERY_KEY]);
  };

  const handleReadMessage = async (messageId: number) => {
    await markMessageRead({ messageId });
    await queryClient.invalidateQueries([MESSAGES_QUERY_KEY]);
  };

  // Handles showing ticket from link redirect
  useEffect(() => {
    if (ticketId) {
      handleShowTicket(ticketId);
      setTicketRedirectId({ ticketId: null });
    }
  }, [ticketId]);

  /* const unreadMessages = messages?.filter((message: any) => !message?.is_read);
  const unreadTickets = tickets?.filter((ticket: any) =>
    ticket.messages.some((message: any) => !message.is_read && message.sender_id !== me?.id)
  ); */

  const commonQueryParams: SupportTicketsQueryParams = {
    perpage: 10,
    page: paginationModel.page + 1,
    extend: [
      SupportTicketExtendOptions.messages,
      SupportTicketExtendOptions.sender,
      SupportTicketExtendOptions.recipient,
      SupportTicketExtendOptions.messagesAttachments
    ]
  };

  const {
    result: ticketsData,
    total: totalTickets,
    isLoading: isLoadingTickets
  } = useSupportTickets(commonQueryParams, true);
  const ticketsExist = ticketsData?.length > 0;

  const showInbox = () => {
    if (isLoadingTickets)
      return (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      );

    return (
      <Card>
        {ticketsExist ? (
          <CustomTable
            tableData={ticketsData}
            tableHeader={t('dashboard:inbox.grid.table_header', 'Inbox')}
            totalItems={totalTickets}
            isLoading={isLoadingTickets}
            disableRowSelectionOnClick
            sx={{ maxHeight: '420px' }}
            columns={[
              {
                field: 'title',
                headerName: t('dashboard:inbox.grid.columns.topic', 'Topic'),
                flex: 1,
                renderCell: (params) => {
                  return <NavLink to={`/tickets/${params.id}`}>{params.value}</NavLink>;
                }
              },
              {
                field: t('dashboard:inbox.grid.columns.sender', 'Sender'),
                headerName: 'From',
                flex: 1,
                sortable: false
              },
              {
                field: t('dashboard:inbox.grid.columns.recipient', 'To'),
                headerName: 'To',
                flex: 1,
                sortable: false
              },
              {
                field: 'lastMessageDate',
                headerName: t('dashboard:inbox.grid.columns.date', 'Date'),
                flex: 1
              }
            ]}
            mapTableData={mapTicketData}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        ) : (
          <NoMessages />
        )}
      </Card>
    );
  };

  return (
    <div>
      {isModalOpen && selectedTicket && (
        <TicketModal ticketId={selectedTicket} handleClose={handleClose} />
      )}
      {isMessageModalOpen && selectedMessage && (
        <MessageModal
          title={selectedMessage?.message?.title}
          content={selectedMessage?.message?.content}
          date={selectedMessage?.message?.created_at_date}
          hour={selectedMessage?.message?.created_at_hour}
          sender={selectedMessage?.message?.sender?.name}
          handleClose={handleMessageModalClose}
          onModalOpen={() =>
            !selectedMessage.is_ready ? handleReadMessage(selectedMessage.id) : null
          }
        />
      )}
      {showInbox()}
      {/* <TabStrip selected={tabSelected} onSelect={(e) => setSelectedTab(e.selected)}>
        {userHasPermissions([RoleEnum.clinician, RoleEnum.clinicAdmin], rolesByName) && (
          <TabStripTab
            title={
              <TabTitle>
                <span>Messages from patients</span>
                {unreadTickets?.length > 0 && <InboxChip>{unreadTickets.length}</InboxChip>}
              </TabTitle>
            }>
            <TicketsGrid
              queryFunction={getSupportTickets}
              queryParams={queryParams}
              handleCloseTicket={handleCloseTicket}
              handleShowTicket={handleShowTicket}
              handleReopenTicket={handleReopenTicket}
            />
          </TabStripTab>
        )}
        <TabStripTab
          title={
            <TabTitle>
              <span>Messages from Aether</span>
              {unreadMessages?.length > 0 && <InboxChip>{unreadMessages.length}</InboxChip>}
            </TabTitle>
          }>
          <MessagesGrid
            queryFunction={getMessages}
            queryParams={queryParams}
            handleShowMessage={handleShowMessage}
            handleArchiveMessage={handleArchiveMessage}
          />
        </TabStripTab>
      </TabStrip> */}
    </div>
  );
};
