import { ComponentPropsWithoutRef } from 'react';
import { ButtonOwnProps } from '@mui/material';
import { themeMui } from 'theme/themeMui';
import { LoadingButton } from '@mui/lab';

interface CustomButton extends ButtonOwnProps {
  children?: React.ReactNode;
  Icon?: any;
  sx?: any;
  iconProps?: any;
  loading?: boolean | undefined;
}

const CustomButton = ({
  children,
  Icon,
  sx,
  iconProps,
  loading,
  ...props
}: CustomButton & ComponentPropsWithoutRef<'button'>) => {
  const style = {
    display: 'flex',
    gap: '4px',
    padding: '10px 14px',
    lineHeight: '20px',
    textTransform: 'none',
    ...(!children && Icon && { minWidth: 0, width: '36px', height: '36px', padding: 0 }),
    ...(!children &&
      Icon &&
      props.size === 'large' && { minWidth: 0, width: '42px', height: '42px', padding: 0 }),
    ...(props.color === 'lightBlue' && { border: '1px solid #D8E3FF' }),
    ...(props.color === 'light' && { border: '1px solid #D0D5DD' }),
    ...(props.variant === 'text' && { fontWeight: '600' }),
    ...sx
  };

  const iconStyle = {
    ...(children && { marginRight: '6px' }),
    ...iconProps?.style,
    ...(!props.color && !props.variant && { stroke: themeMui.palette.primary.contrastText }),
    ...(!props.color && props.variant === 'text' && { stroke: themeMui.palette.primary.main }),
    ...(props.color && { stroke: themeMui.palette[props.color].contrastText }),
    ...(props?.disabled && { stroke: '#98A2B3', color: '#98A2B3' })
  };

  return (
    <LoadingButton sx={style} {...props} loading={loading}>
      {Icon && <Icon {...iconProps} style={iconStyle} />} {children && <span>{children}</span>}
    </LoadingButton>
  );
};

export default CustomButton;
